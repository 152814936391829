import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilterModel from '../../data/filter-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';

const AddFilter = props => {
    const [filterForm, setFilterForm] = useState({ name: '', acronym: '' });
    const [block, setBlock] = useState(false);
    const history = useHistory();
    const param = props.component
        ? props.component.location.pathname.split('/add-filter/')[1]
        : undefined;

    useEffect(() => {
        if (param) {
            let values = param.split('/');

            if (values.length === 3) {
                setFilterForm({
                    name: values[1] ? values[1] : '',
                    acronym: values[2] ? values[2] : '',
                    id: values[0] ? values[0] : undefined,
                });
            } else {
                setFilterForm({
                    name: values[0] ? values[0] : '',
                    acronym: values[1] ? values[1] : '',
                    id: undefined,
                });
            }

        }

        return () => {
            setFilterForm({});
        };
    }, []);

    const onSubmitFilterForm = (event) => {
        let values = param ? param.split('/') : null;
        event.preventDefault();
        if (FilterModel.providerId) {
            filterForm.id
                ? FilterModel.updateProviderFilter(filterForm).then(() => history.push('/filters')).catch(err => toast(err.message, { autoClose: 2000 }))
                : values?.[0] === filterForm.name || values?.[1] === filterForm.acronym
                    ? toast(getTranslatedText('cloneFiltersWarning', store))
                    : FilterModel.createProviderFilter({ ...filterForm, restId: FilterModel.providerId }).then(() => history.push('/filters')).catch(err => toast(err.message, { autoClose: 2000 }))
        } else {
            filterForm.id
                ? FilterModel.updateFilter(filterForm).then(() => history.push('/filters')).catch(err => toast(err.message, { autoClose: 2000 }))
                : values?.[0] === filterForm.name || values?.[1] === filterForm.acronym
                    ? toast(getTranslatedText('cloneFiltersWarning', store))
                    : FilterModel.createFilter(filterForm).then(() => history.push('/filters')).catch(err => toast(err.message, { autoClose: 2000 }))
        }
        event.stopPropagation();
        setBlock(false)
    };

    const cancelAction = () => {
        setBlock(false)
        history.push('/filters');
    };

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className='site-header'>
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText('addFilter', store)}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button onClick={cancelAction} type='button' className="btn">
                                            {getTranslatedText('cancel', store)}
                                        </button>
                                        <button onClick={onSubmitFilterForm} type='submit' className="btn default-btn">
                                            {getTranslatedText('save', store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="spacing">
                            <form >
                                <div className="info info-title" data-info="filter_name">
                                    <label htmlFor="filter_name">
                                        {getTranslatedText('filterName', store)}
                                        <sup>(*)</sup>
                                    </label>
                                    <input id="filter_name"
                                        name="filter_name"
                                        value={filterForm.name}
                                        onChange={event =>
                                            setFilterForm({
                                                ...filterForm,
                                                name: event.target
                                                    .value,
                                            })}

                                        type="text" />
                                </div>
                                <div className="info info-title" data-info="filter_name">
                                    <label htmlFor="filter_name">
                                        {getTranslatedText('acronym', store)}
                                        <sup>(*)</sup>
                                    </label>
                                    <input
                                        id="filter_name"
                                        name="filter_name"
                                        value={filterForm.acronym}
                                        onChange={event => {
                                            if (event.target.value.length > 2) {
                                                if (!block) {
                                                    toast('The Acronym must be up to 2 letters')
                                                    setBlock(true)
                                                }
                                            } else {
                                                setFilterForm({
                                                    ...filterForm,
                                                    acronym: event.target.value,
                                                })
                                                setBlock(false)
                                            }
                                        }}
                                        type="text" />
                                </div>
                            </form>

                        </main>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AddFilter;
