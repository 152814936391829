import React, { Component } from 'react';
import { useState } from 'react';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import './fee.css';
const FeeModal = ({ setUpdateFee, updateFee, hideModal, id, setProviderForm, providerForm, fee }) => {

    const [feeForm, setFeeForm] = useState({ name: fee ? fee.name : '', value: fee ? fee.value : 0, type: fee ? fee.type : 0 });
    const [showError, setShowError] = useState(false)
    let options = [
        {
            value: 0,
            name: getTranslatedText('add', store),
        },
        {
            value: 1,
            name: getTranslatedText('multiply', store),
        },
    ];

    const updatedItem = (array, fee) => {
        return array.map((item, index) => {
            if (index === id) {
                return fee;
            }
            return item;
        });
    };

    const addfee = () => {
        if (feeForm.name && feeForm.value) {
            setProviderForm({
                ...providerForm,
                fees: {
                    fees: providerForm.fees.fees && providerForm.fees.fees.length > 0 && updateFee
                        ? updatedItem(providerForm.fees.fees, feeForm)
                        : providerForm.fees.fees && providerForm.fees.fees.length > 0 && !updateFee
                            ? providerForm.fees.fees.concat(feeForm)
                            : [{ ...feeForm }],
                    taxes: providerForm.fees.taxes,
                    extraTaxes: providerForm.fees.extraTaxes,
                },
            });
            if (updateFee) {
                setUpdateFee(false);
            }
            hideModal();
        } else {
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
            }, 4000);
        }
    };

    return (
        <div
            id="modal-add-fee"
            data-attribute="modal-window"
            className="modal-container">
            <div className="modal-wrapper">
                <button
                    type="button"
                    className="close-modal"
                    onClick={hideModal}
                    data-dismiss="modal">
                    x
                </button>
                <div className="modal-head">
                    <p className="modal-title">
                        {getTranslatedText('addNewFee', store)}
                    </p>
                </div>
                <div className="modal-body">
                    <div className="info">
                        <label htmlFor="identifier-fee">{getTranslatedText('name', store)} <sup>(*)</sup></label>
                        <input
                            id="identifier-fee"
                            name="identifier-fee"
                            type="text"
                            value={feeForm.name}
                            onChange={event =>
                                setFeeForm({
                                    ...feeForm,
                                    name: event.target
                                        .value,
                                })}
                        />
                    </div>
                    <div className="info">
                        <label htmlFor="value-1-fee">
                            {getTranslatedText('costPercentage', store)} <sup>(*)</sup>
                        </label>
                        <input
                            id="value-1-fee"
                            name="value-1-fee"
                            type="text"
                            value={feeForm.value === 0 ? '' : feeForm.value}
                            defaultValue={null}
                            min={0}
                            max={100}
                            onChange={event => {
                                const regex = /[0-9]+/g
                                setFeeForm({
                                    ...feeForm,
                                    value: !event.target.value.match(regex) ? '' : event.target.value > 100 ? 100 : event.target.value,
                                })
                            }}
                        />
                    </div>
                    <div className="info">
                        <label htmlFor="value-2-fee">{getTranslatedText('attribute', store)} <sup>(*)</sup></label>
                        <select
                            className='select-wrapper'
                            value={feeForm.type}
                            onChange={event => {
                                setFeeForm({
                                    ...feeForm,
                                    type: parseInt(event.target.value),
                                });
                            }
                            }
                            name="value-2-fee" id="value-2-fee">
                            {options.map(item => <option key={item.value + 'opt_item'} value={item.value} >{item.name}</option>)}

                        </select>
                    </div>
                </div>
                {showError && <span style={{ color: 'red' }}>{getTranslatedText('addAllFields', store)}</span>}
                <div className="modal-footer">
                    <div className="flex-start">
                        <button
                            type="button"
                            className="clear"
                            onClick={hideModal}
                            data-dismiss="modal">
                            {getTranslatedText('cancel', store)}
                        </button>
                        <button onClick={addfee} className="default-btn">
                            {getTranslatedText('save', store)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

};
export default FeeModal;
