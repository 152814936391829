import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import { default as plus } from '../plus-sign.svg';

const ProviderGroups = () => {

    return (
        <>
            <Link to="/provider-group-info">
                <p data-component="provider">
                    {getTranslatedText('providerGroups', store)}
                </p>
            </Link>
            <Link to={'/add-provider-group'}>
                <button className="btn add-btn">
                    <span>
                        <img src={plus} alt="add" />
                    </span>
                </button>
            </Link>
        </>
    );
};
export default ProviderGroups;
