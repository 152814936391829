import React, { Component } from 'react';
import { useState } from 'react';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';

const ExtraTaxesModal = ({ extraTaxe, updateExtraTaxe, setUpdateExtraTaxe, id, hideModal, setProviderForm, providerForm }) => {
    const [extraTaxeForm, setExtraTaxeForm] = useState({ name: extraTaxe ? extraTaxe.name : '', percentage: extraTaxe ? extraTaxe.percentage : null });
    const [showError, setShowError] = useState(false)

    const updatedItem = (array, fee) => {
        return array.map((item, index) => {
            if (index === id) {
                return fee;
            }
            return item;
        });
    };

    const addtax = () => {
        if (extraTaxeForm.name && extraTaxeForm.percentage) {
            setProviderForm({
                ...providerForm,
                fees: {
                    fees: providerForm.fees.fees,
                    taxes: providerForm.fees.taxes,
                    extraTaxes: providerForm.fees.extraTaxes && providerForm.fees.extraTaxes[0] && updateExtraTaxe
                        ? updatedItem(providerForm.fees.extraTaxes, extraTaxeForm)
                        : providerForm.fees.extraTaxes && providerForm.fees.extraTaxes[0] && !updateExtraTaxe
                            ? providerForm.fees.extraTaxes.concat(extraTaxeForm)
                            : [{ ...extraTaxeForm }],
                },
            });
            if (updateExtraTaxe) {
                setUpdateExtraTaxe(false);
            }
            hideModal();
        } else {
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
            }, 4000);
        }
    };

    return (

        <div
            id="modal-add-tax"
            data-attribute="modal-window"
            className="modal-container">
            <div className="modal-wrapper">
                <button
                    type="button"
                    className="close-modal"
                    onClick={hideModal}
                    data-dismiss="modal">
                    x
                </button>
                <div className="modal-head">
                    <p className="modal-title">
                        {getTranslatedText('addNewExtraTax', store)}
                    </p>
                </div>
                <div className="modal-body">
                    <div className="info">
                        <label htmlFor="identifier-tax">
                            {getTranslatedText('name', store)}
                            <sup>(*)</sup>
                        </label>
                        <input
                            id="identifier-tax"
                            name="identifier-tax"
                            type="text"
                            value={extraTaxeForm.name}
                            onChange={event =>
                                setExtraTaxeForm({
                                    ...extraTaxeForm,
                                    name: event.target.value > 1000 ? 1000 : event.target.value,
                                })}
                        />
                    </div>
                    <div className="info">
                        <label htmlFor="value-1-tax">
                            {getTranslatedText('percentage', store)}
                            <sup>(*)</sup>
                        </label>
                        <input
                            id="value-1-tax"
                            name="value-1-tax"
                            type="text"
                            value={extraTaxeForm.percentage === 0 ? '' : extraTaxeForm.percentage}
                            defaultValue=''
                            min={0}
                            max={100}
                            onChange={event => {
                                const regex = /[0-9]+/g
                                setExtraTaxeForm({ ...extraTaxeForm, percentage: !event.target.value.match(regex) ? '' : event.target.value > 100 ? 100 : event.target.value })
                            }}
                        />
                    </div>

                </div>
                {showError &&
                    <span style={{ color: 'red' }}>
                        {getTranslatedText('addAllFields', store)}
                    </span>
                }

                <div className="modal-footer">
                    <div className="flex-start">
                        <button
                            type="button"
                            className="clear"
                            onClick={hideModal}
                            data-dismiss="modal">
                            {getTranslatedText('cancel', store)}
                        </button>
                        <button onClick={addtax} className="default-btn">
                            {getTranslatedText('save', store)}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default ExtraTaxesModal;
