import React, { useEffect, useState } from 'react';
import RootCategoryModel from '../../data/rootCategory-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import CategoryIcons from '../category-icons/category-icons';
import InfoModal from '../info-modal';

const RootCategoryCategory = ({ categoryItem, setRootCategoryCategoryItems, rootCategoryCategoryItems, setSelectedCategory, selectedCategory, drag }) => {
    const [show, setShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    })


    useEffect(() => {
        if (infoMessage.confirmation) {
            RootCategoryModel.deleteRootCategoryCategory(categoryItem.id).then(res => {
                setRootCategoryCategoryItems(
                    rootCategoryCategoryItems.filter(itm => itm.id !== categoryItem.id),
                );
                setSelectedCategory(null)
                setRootCategoryCategoryItems(rootCategoryCategoryItems.filter(itm => itm.id !== categoryItem.id))
            });
        }
    }, [infoMessage.confirmation])

    const hideModal = () => {
        setShow(false);
    };

    const deleteRootCategoryCategory = () => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteCategoryWarning', store),
        })
    };

    return (
        <div className="carousel-cell">
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show} />
            <div className={'tab-carousel-icon link' +
                (categoryItem && selectedCategory && categoryItem.id === selectedCategory.id && !drag
                    ? ' active-icon' : '')} onClick={() => {
                        !drag && setSelectedCategory(categoryItem)
                    }}>
                {drag && <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: -100 }}>
                    <circle cx="2.45455" cy="2.45455" r="2.45455" fill="white" />
                    <circle cx="2.45455" cy="9.0002" r="2.45455" fill="white" />
                    <circle cx="2.45455" cy="15.5454" r="2.45455" fill="white" />
                    <circle cx="9.81807" cy="2.45455" r="2.45455" fill="white" />
                    <circle cx="9.81807" cy="9.0002" r="2.45455" fill="white" />
                    <circle cx="9.81807" cy="15.5454" r="2.45455" fill="white" />
                </svg>}
                <img src={CategoryIcons.getIconUrlByName(categoryItem.images[0])} width="75" />
                <p className="category-description">{categoryItem.name}</p>
                <button onClick={deleteRootCategoryCategory} type="button" className="remove-category">x</button>
            </div>
        </div>
    );
};
export default RootCategoryCategory;
