import Config from '../Config';
import Services from './services';

const TimeFrameModel = {
    getAllTimeFramesByProviderId: (id) => {
        return Services.getApi(Config.getBasePath() + `/providers/${id}/timeframes`);
    },
    update: (id, timeFrames) => {
        return Services.postApi(Config.getBasePath() + `/providers/${id}/timeframes`, timeFrames);
    },
};

export default TimeFrameModel;
