import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import './rootCategory-item-modal.css';
import HCNImages from '../../helpers/hcn-images';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const RootCategoryItemModal = ({ modalItem, sectionItems, featureSectionItems, setFeatureSectionItems, setSectionItems, type, active }) => {

    const [selected, setSelected] = useState(!!featureSectionItems.find(item => item.id === modalItem.id));

    useEffect(() => {
        setSectionItems(featureSectionItems)
    }, [])

    const onChangeCheck = ({ target: { checked } }) => {
        setSelected(checked)
        setSectionItems(checked ? [...sectionItems, { ...modalItem, suggested: !active }] : sectionItems.filter(it => it.id !== modalItem.id))
        setFeatureSectionItems(checked ? [...sectionItems, { ...modalItem, suggested: !active }] : sectionItems.filter(it => it.id !== modalItem.id))
    }

    return (
        <div className="flex-start-start mod-options-container height-reset border-bottom">
            <span className="padding-top margin-right">
                <label className="custom-input-container">

                    <input
                        onChange={onChangeCheck}
                        checked={selected}
                        type="checkbox"
                    />
                    <span className="checkmark"></span>
                </label>
            </span>
            <div className="option-item modal flex-between padding-top padding-bottom">
                <div className="image-wrapper grid-flex">
                    <span className="image-container">
                        <img src={HCNImages.getFullImageURL(modalItem.images[0], modalItem.images[0]?.thumbnail ? 'thumbnail' : modalItem.images[0]?.default ? 'default' : 'main')} width="100" />
                        <span className="default-image-layer"></span>
                    </span>
                    <div className="margin-left-half">
                        <p className="rootCategory-item-title">{modalItem.name}</p>
                        <p className="text-small margin-top-half desc">{modalItem.description}</p>
                    </div>
                </div>

                <div className="flex-between-start">
                    {type === 'category' &&
                        <div className="boxed-price-container changed">
                            <span className="flex-start boxed-price">$</span>
                            <input
                                className="flex-start boxed-price"
                                type={'number'}
                                min={0}
                                max={1000}
                                defaultValue={modalItem.price2 ? modalItem.price2 : modalItem.price}
                                onChange={({ target: { value } }) => {
                                    setSectionItems(sectionItems.map(item => item.id === modalItem.id
                                        ? { ...item, price: value, price2: value > 1000 ? 1000 : value }
                                        : { ...item }))
                                    setFeatureSectionItems(featureSectionItems.map(item => item.id === modalItem.id
                                        ? { ...item, price: value, price2: value > 1000 ? 1000 : value }
                                        : { ...item }))
                                }}
                            />
                            <p className="message-changed">{getTranslatedText('changedValue', store)}</p>
                        </div>
                    }
                    {type !== 'category' &&
                        <div className="boxed-price-container changed">
                            <span className="flex-start boxed-price">${modalItem.price2 ? modalItem.price2 : modalItem.price}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default RootCategoryItemModal;
