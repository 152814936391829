import React, { Component } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import Filter from './filter';

const Filters = () => {
    const [filters, setFilters] = useState([]);
    const history = useHistory();

    useState(() => {
        FilterModel.providerId ? FilterModel
            .remote_getAllById(FilterModel.providerId)
            .then(filters => {
                if (filters.length === 0) {
                    history.push('/import-default-filter');
                }
                setFilters(filters);
            }) :
            FilterModel
                .remote_getAll()
                .then(filters => {
                    if (filters.length === 0) {
                        history.push('/add-filter');
                    }
                    setFilters(filters);
                });
        return () => {
            setFilters([]);
        };
    }, []);

    const addFilter = () => {
        history.push('/add-filter');
    };

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">
                                {getTranslatedText('filters', store)}
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    {FilterModel.providerId ?
                                        <>
                                            <button
                                                onClick={() => {
                                                    history.push('/import-default-filter')
                                                }}
                                                className="btn default-btn"
                                            >
                                                {getTranslatedText('importFilter', store)}
                                            </button>
                                            <button onClick={addFilter} className="btn default-btn">
                                                {getTranslatedText('addFilter', store)}
                                            </button>
                                        </>
                                        : <button onClick={addFilter} className="btn default-btn">
                                            {getTranslatedText('addFilter', store)}
                                        </button>}
                                    <MainMenuBtn />
                                </div>
                            </div>

                        </div>
                    </header>
                    <main>
                        {filters.length > 0 ? filters.map((filter, index) =>
                            <Filter filters={filters} setFilters={setFilters} key={index} filter={filter} />,
                        ) : <></>}
                    </main>
                </div>
            </div>
        </div>
    );
};
export default Filters;
