import Config from '../Config';
import Services from './services';

const ModifierModel = {
    getProviderModifiersById: (id) => {
        return Services.getApi(Config.getBasePath() + `/providers/${id}/modifiers`);
    },
    createProviderModifiers: (modifiers) => {
        return Services.postApi(Config.getBasePath() + '/provider-modifiers', modifiers);
    },
    getProviderModifierById: (id) => {
        return Services.getApi(Config.getBasePath() + `/provider-modifiers/${id}`);
    },
    updateProviderModifiers: (modifier, id) => {
        return Services.putApi(Config.getBasePath() + `/provider-modifiers/${id}`, modifier);
    },

    deleteProviderModifierById: (modifierId) => {
        return Services.deleteApi(Config.getBasePath() + `/provider-modifiers/${modifierId}`);
    },
    remote_getAll: () => {
        return Services.getApi(Config.getBasePath() + '/modifiers');
    },
    copyModifier: (modifier) => {
        return Services.postApi(Config.getBasePath() + '/modifiers', modifier);
    },
    deleteModifier: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/modifiers/${id}`);
    },
    getModifierById: (id) => {
        return Services.getApi(Config.getBasePath() + `/modifiers/${id}`);
    },
    createModifier: (modifier) => {
        return Services.postApi(Config.getBasePath() + '/modifiers', modifier);
    },
    updateModifier: (id, modifier) => {
        return Services.putApi(Config.getBasePath() + `/modifiers/${id}`, modifier);
    },
};

export default ModifierModel;
