import React, { Component, useEffect, useState } from 'react';
import RootCategoryModel from '../../../data/rootCategory-model';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import InfoModal from '../../info-modal';


const SectionButton = ({ fsect, rootCategorySection, rootCategorySections, setRootCategorySections, setRootCategorySection, setSelectedCategory, drag }) => {
    const [show, setShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });


    useEffect(() => {
        if (infoMessage.confirmation) {
            setRootCategorySection(null)
            setRootCategorySections(rootCategorySections.filter(item => item.id !== fsect.id))
            RootCategoryModel.deleteRootCategorySection(fsect.id)
                .then(res => console.log(res))
                .catch((error) => console.log(error))
        }
    }, [infoMessage.confirmation])

    const hideModal = () => {
        setShow(false);
    };
    const setSection = () => {
        if (!drag) {
            setRootCategorySection({
                ...fsect,
                id: fsect.id,
                rootCategoryId: fsect.rootCategoryId,
            });
            setSelectedCategory(null)
        }
    };

    const deleteRootCategorySection = () => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteSectionWarning', store),
        })
    }

    return (
        <div>
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show}
            />
            <div className='category-btn-item'>
                {drag && <button type="button" style={{ marginTop: 2 }}>
                    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2.45455" cy="2.45455" r="2.45455" fill="white" />
                        <circle cx="2.45455" cy="9.0002" r="2.45455" fill="white" />
                        <circle cx="2.45455" cy="15.5454" r="2.45455" fill="white" />
                        <circle cx="9.81807" cy="2.45455" r="2.45455" fill="white" />
                        <circle cx="9.81807" cy="9.0002" r="2.45455" fill="white" />
                        <circle cx="9.81807" cy="15.5454" r="2.45455" fill="white" />
                    </svg>
                </button>}
                <button onClick={setSection} type="button" className={'tab' + (rootCategorySection && fsect && rootCategorySection.id === fsect.id ? ' active' : '')}>{fsect.name}</button>
                <sup className='padding-left padding-right margin-left-30 pointer' onClick={deleteRootCategorySection} >x</sup>
            </div>

        </div>
    );
};
export default SectionButton;
