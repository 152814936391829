import React from 'react';

const HCNString = {
    formatDashedString: (text, capitalize = true) => {
        if (!text || text.length === 0) {
            return '';
        }
        text = text.replace(/-/g, ' ');

        return capitalize ? HCNString.capitalizeFirstLetter(text) : text;
    },
    capitalizeFirstLetter: (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
};

export default HCNString;
