import { marketplace } from '../TranslationsMarketplace'
import { dinein } from '../TranslationsDineIn'
import Config from '../Config'

export const checkTranslations = (dispatch) => {
    const isDineIn = Config.getDineIn()


    if (isDineIn) {
        dispatch({
            type: Config.REDUCER_TYPES.SET_DINEIN,
            payload: dinein,
        });
    } else {
        dispatch({
            type: Config.REDUCER_TYPES.SET_DINEIN,
            payload: marketplace,
        });
    }
}

export const getTranslatedText = (data, store) => {
    const rdx = store.getState().Reducer.dinein;
    return rdx.en?.[data];
}
