import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { getTranslatedText } from "../../helpers/checkTranslations";
import { store } from "../../redux/store";
import MainMenuBtn from "../menu-settings/main-menu-btn";
import SideBar from "../side-bar/side-bar";

const Welcome = (props) => {
  const history = useHistory();

  const addProvider = () => {
    history.push("/provider-info");
  };

  console.log(12334, store.getState().Reducer.isAdmin);

  return (
    <div className="welcome">
      <div className="divider grid-flex">
        <SideBar />
        <div id="page" className="site">
          <div className="main-content">
            <header className="site-header">
              <div className="flex-between">
                <h3 className="main-title">
                  {getTranslatedText("welcomeText", store)}
                </h3>
                <div className="side-functionality">
                  <div className="side-button grid-flex">
                    <button onClick={addProvider} className="btn default-btn">
                      {getTranslatedText("add", store)}
                    </button>
                    <MainMenuBtn />
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
