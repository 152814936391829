import React, { Component } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import Taxe from './taxe';
import TaxesModal from './taxeModal';
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};

const Taxes = ({ providerForm, setProviderForm }) => {

    const [show, setShow] = useState(false);
    const showModal = () => {
        providerForm.fees.fees.fees && providerForm.fees.fees.fees.length === 0
            ? toast('You have to add a Fee first!')
            : setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };
    return (
        <>
            <div style={{ marginBottom: 12 }} className="fees-head flex-between">
                <h2>{getTranslatedText('taxes', store)}</h2>
                <ReactModal
                    isOpen={show}
                    style={customStyles}
                    contentLabel="Minimal Modal Example"
                    ariaHideApp={false}
                >
                    <TaxesModal setProviderForm={setProviderForm} providerForm={providerForm} hideModal={hideModal} />

                </ReactModal>
                <button
                    type="button"
                    className="default-btn tax"
                    data-attribute="modal-action"
                    onClick={showModal}
                    data-type="modal-add-tax"
                >
                    {getTranslatedText('addTax', store)}
                </button>
            </div>
            {providerForm.fees.taxes && providerForm.fees.taxes.length > 0 && <div className="fees-content">
                <div className="fees-row-head">
                    <span className="col-3">{getTranslatedText('name', store)}</span>
                    <span className="col-3 text-center">{getTranslatedText('percentage', store)}</span>
                    <span className="col-3 text-center">{getTranslatedText('appliedTo', store)}</span>
                </div>
                {providerForm.fees.taxes && providerForm.fees.taxes.length > 0
                    ? providerForm.fees.taxes.map((taxe, index) => <Taxe id={index} setProviderForm={setProviderForm} providerForm={providerForm} key={index} taxe={taxe} />)
                    : []
                }
            </div>}
        </>
    );
};

export default Taxes;
