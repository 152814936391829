import React, { Component } from 'react';
import '../rootCategory.css';
import HCNString from '../../../helpers/hcn-string';

const CategoryIcon = ({ component, categoryForm, setCategoryForm, categories, setCategories }) => {
    const selectIcon = () => {
        setCategories(
            categories.map(item => item.name === component.name ? ({ ...item, selected: true }) : ({ ...item, selected: false })
            ));

        setCategoryForm({
            ...categoryForm,
            images: [component.name],
        });

    };

    return (
        <span onClick={selectIcon} style={{ display: 'flex', flexDirection: 'column' }} className={`icon ${categories && categories.find(x => x.name === component.name)?.selected ? 'is-active' : ''}`}>
            <img src={component.icon} alt={HCNString.formatDashedString(component.name)} title={HCNString.formatDashedString(component.name)} width="75" />
            <span style={{ fontSize: 10, textAlign: 'center' }}>{HCNString.formatDashedString(component.name)}</span>
        </span>
    );
};
export default CategoryIcon;
