import React, { Component } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import TaxesModal from './taxeModal';

const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};
const Taxe = ({ taxe, id, providerForm, setProviderForm }) => {
    const [show, setShow] = useState(false);
    const [updateTaxe, setUpdateTaxe] = useState(false);
    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        if (updateTaxe) {
            setUpdateTaxe(false);
        }
        setShow(false);
    };
    const editTaxe = () => {
        setUpdateTaxe(true);
        showModal();
    };
    const deleteTaxe = () => {
        setProviderForm({
            ...providerForm,
            fees: {
                fees: providerForm.fees.fees,
                taxes: providerForm.fees.taxes.filter(el => el !== taxe),
                extraTaxes: providerForm.fees.extraTaxes,
            },

        });
    };

    return (
        <>
            <div className="fees-row-body flex-between add-row-action">
                <ReactModal
                    isOpen={show}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <TaxesModal
                        id={id}
                        setUpdateTaxe={setUpdateTaxe}
                        updateTaxe={updateTaxe}
                        taxe={taxe}
                        setProviderForm={setProviderForm}
                        providerForm={providerForm}
                        hideModal={hideModal} />

                </ReactModal>
                <div className="fees-row-content">
                    <span
                        onClick={editTaxe}
                        className="col-3 fees-identifier"
                        data-fee="identifier">
                        {taxe.name}
                    </span>
                    <span
                        className="col-3 fees-value-1 text-center"
                        data-fee="value-1">
                        {taxe.percentage}%
                    </span>
                    <span
                        className="col-3 fees-value-2 text-center"
                        data-fee="value-2">
                        {taxe.appliedTo ? taxe.appliedTo.map(el => <span key={el}>{el}</span>) : <span></span>}
                    </span>
                </div>
                <div className="fees-row-options">
                    <button
                        onClick={showModal}
                        type="button"
                        className="copy">
                        <img src=".././img/svg/duplicate.svg" alt="double item" />
                    </button>
                    <button
                        onClick={deleteTaxe}
                        type="button"
                        className="remove">
                        <img src=".././img/svg/trash.svg" alt="trash" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default Taxe;
