import React, { Component } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import ExtraTaxesModal from './extraTaxeModal';
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};
const ExtraTaxe = ({ extraTaxe, id, providerForm, setProviderForm }) => {
    const [updateExtraTaxe, setUpdateExtraTaxe] = useState(false);
    const [show, setShow] = useState(false);
    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        if (updateExtraTaxe) {
            setUpdateExtraTaxe(false);
        }
        setShow(false);
    };
    const editExtraTaxe = () => {
        setUpdateExtraTaxe(true);
        showModal();
    };
    const deleteExtraTaxe = () => {

        setProviderForm({
            ...providerForm,
            fees: {
                fees: providerForm.fees.fees,
                taxes: providerForm.fees.taxes,
                extraTaxes: providerForm.fees.extraTaxes.filter(el => el !== extraTaxe),
            },
        });
    };
    return (
        <>
            <div className="fees-row-body flex-between add-row-action">
                <ReactModal
                    isOpen={show}
                    style={customStyles}
                    contentLabel="Minimal Modal Example"
                    ariaHideApp={false}
                >
                    <ExtraTaxesModal
                        id={id}
                        updateExtraTaxe={updateExtraTaxe}
                        setUpdateExtraTaxe={setUpdateExtraTaxe}
                        extraTaxe={extraTaxe}
                        setProviderForm={setProviderForm}
                        providerForm={providerForm}
                        hideModal={hideModal} />

                </ReactModal>
                <div className="fees-row-content">
                    <span
                        className="col-3 fees-identifier"
                        onClick={editExtraTaxe}
                        data-fee="identifier">
                        {extraTaxe.name}
                    </span>
                    <span
                        className="col-3 fees-value-1 text-center"
                        data-fee="value-1">
                        {extraTaxe.percentage}%
                    </span>

                </div>
                <div className="fees-row-options">
                    <button
                        onClick={showModal}
                        type="button"
                        className="copy">
                        <img src=".././img/svg/duplicate.svg" alt="double item" />
                    </button>
                    <button
                        type="button"
                        onClick={deleteExtraTaxe}
                        className="remove">
                        <img src=".././img/svg/trash.svg" alt="trash" />

                    </button>
                </div>
            </div>
        </>
    );

};
export default ExtraTaxe;
