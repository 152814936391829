import React, { Component } from 'react';
import Config from '../../Config';

const categoryIcons = Config.getDineIn()
    ? {
        generic: [
            'breakfast',
            'kids',
            'apperizer-snack',
            'entree-appetizer',
            'for-two',
            'main-appetizer-entree',
            'main-chef',
            'meal-for-two',
        ],
        drinks: [
            'alchoho-drink-cocktail',
            'alcohol-drink',
            'beer-alcohol-drink',
            'beverage-juice',
            'cocktail-drink-alcohol',
            'coffee-breakfast',
            'drink-juice-soda',
            'juice-beverage',
            'soda-drink',
            'soda-juice-drink',
            'tea-beverage',
            'water-drink',
            'wine-alcohol-champagne',
            'wine-alcohol-drink',
            'wine-bottle-alcohol',
        ],
        food: [
            'bagels-breakfast-snack',
            'bread',
            'burger-entree',
            'buritto',
            'cheese',
            'chicken-meat-meal',
            'chicken-turkey-meat',
            'cookie-sweet-kids',
            'croissant-snack',
            'crustaceans-lobster',
            'cupcake-dessert-sweet',
            'desser-cake-sweet',
            'donut-breakfast-sweet',
            'dumpling',
            'egg-breakfast',
            'eggs-breakfast',
            'fish',
            'fries-kids-snack',
            'fruit',
            'hotdog',
            'ice-cream-dessert',
            'muffin-dessert-sweet',
            'noodles',
            'pancake-breakfast',
            'pizza',
            'rice',
            'rice-entree',
            'risotto',
            'salad-entree',
            'sandwich-brunch-snack-entree',
            'sandwich-pannini-brunch',
            'shellfish',
            'shellfish-oyster',
            'shrimp-shellfish-crustaceans',
            'soup-main-dish',
            'spaghetti',
            'steak-bbq-meat',
            'sushi',
            'taco-brunch-snack',
            'toast',
        ],
    }
    : {
        Services: [
            'barber',
            'cleaning-tools',
            'cleaning',
            'crib',
            'facial',
            'foot-massage',
            'hair-salon',
            'hairdryer',
            'health-care',
            'ironing',
            'laundry',
            'linen',
            'massage',
            'mop',
            'nail-polish',
            'nanny',
            'pet-care',
            'pet',
            'pillows',
            'repairs',
            'robe',
            'shampoo',
            'slippers',
            'spa',
            'sweep',
            'towels',
            'vacuum',
            'valet',
            'valet',
            'vet',
        ]
    }

const getIconUrlByName = icon => {
    let url = '';
    let iconCategories = Object.keys(categoryIcons);
    for (const iconCategory of iconCategories) {
        if (categoryIcons[iconCategory].find(x => x === icon)) {
            url = Config.getDineIn() ? `/icons/${iconCategory}/${icon}.svg` : `/icons/gs/${icon}.svg`;
            break;
        }
    }

    return url;
};

const getIconList = () => {
    let icons = [];
    let iconCategories = Object.keys(categoryIcons);
    for (const iconCategory of iconCategories) {
        icons = [...icons, ...categoryIcons[iconCategory]];
    }

    return icons;
};

export default {
    icons: categoryIcons,
    getIconUrlByName: getIconUrlByName,
    getIconList: getIconList,
};
