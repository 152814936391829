import React, { Component, useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import FilterModel from '../../data/filter-model';
import ModifierModel from '../../data/modifier-model';
import ProviderModel from '../../data/provider-model';
import './rootCategory-items.css';
import ItemModel from '../../data/item-model';
import HCNLoader from '../../helpers/hcn-loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const ProviderModifiersFilters = ({ rootCategoryItem, setRootCategoryItem, itemId, editMode }) => {
    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState([]);
    const [modifiers, setModifiers] = useState([]);
    const [extraTaxes, setExtraTaxes] = useState([]);
    const [initialModifiers, setInitialModifiers] = useState(null);
    const [initialFilters, setInitialFilters] = useState(null);
    const [initialExtraTaxes, setInitialExtraTaxes] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        HCNLoader.show(dispatch);
        ModifierModel.getProviderModifiersById(FilterModel.providerId).then(modifiers => {
            setModifiers(modifiers.map(modif => {
                return {
                    name: modif.name,
                    value: modif.id,
                };
            }));
        });

        FilterModel
            .remote_getAllById(FilterModel.providerId)
            .then(flts => {
                setFilters(flts.map(fil => {
                    return {
                        name: fil.name,
                        value: fil.id,
                    };
                }));
            });

        ProviderModel.remote_getOne(FilterModel.providerId).then(provider => {
            setExtraTaxes(provider.fees.extraTaxes);
        });

        ItemModel
            .getRootCategoryItems(FilterModel.providerId)
            .then(res => {
                if (res) {
                    setItems(res);
                    HCNLoader.hide(dispatch);
                }
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    }, []);

    useEffect(() => {
        if (
            !initialModifiers &&
            !initialFilters &&
            !initialExtraTaxes &&
            editMode
        ) {
            setInitialModifiers(rootCategoryItem?.modifiers);
            setInitialFilters(rootCategoryItem?.filters);
            setInitialExtraTaxes(rootCategoryItem?.taxes);
        }
    }, [rootCategoryItem]);

    const onSelectModifier = (modifierList) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            modifiers: modifierList.map(modif => modif.value),
        });
    };
    const onRemoveModifier = (modifierList) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            modifiers: modifierList.map(modif => modif.value),
        });
    };
    const onSelectFilter = (filtersList) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            filters: filtersList.map(modif => modif.value),
        });
    };
    const onRemoveFilter = (filtersList) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            filters: filtersList.map(modif => modif.value),
        });
    };
    const onSelectTaxes = (extraTaxes) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            taxes: extraTaxes.map(it => it.name),
        });
    };
    const onRemoveTaxes = (extraTaxes) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            taxes: extraTaxes.map(it => it.name),
        });
    };

    const onSelectUpsells = (up) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            upsells: up.map(x => ({ name: x.name, id: x.value ? x.value : x.id })),
        });
    };
    const onRemoveUpsells = (up) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            upsells: up.map(x => ({ name: x.name, id: x.value ? x.value : x.id })),
        });
    };

    useEffect(() => {
        return () => {
            setModifiers([]);
            setFilters([]);
            setExtraTaxes([]);
        };
    }, []);

    return (
        <div className="section-body">
            <div className="">
                <div className="add-options margin-top-double">
                    <label htmlFor="select-modifier">
                        {getTranslatedText('modifiers', store)}
                    </label>
                    <Multiselect
                        options={modifiers}
                        onSelect={onSelectModifier}
                        onRemove={onRemoveModifier}
                        displayValue="name"
                        selectedValues={initialModifiers ? initialModifiers.filter(m => m !== null).map(m => {
                            return {
                                name: m.name,
                                value: m.id,
                            };
                        }) : []}
                    />

                </div>
                <div className="add-options margin-top-double">
                    <label htmlFor="select-filters">
                        {getTranslatedText('filters', store)}
                    </label>
                    <Multiselect
                        options={filters}
                        onSelect={onSelectFilter}
                        onRemove={onRemoveFilter}
                        displayValue="name"
                        selectedValues={initialFilters ? initialFilters.filter(f => f !== null).map(f => {
                            return {
                                name: f.name,
                                value: f.id,
                            };
                        }) : []}
                    />
                </div>
                <div className="add-options margin-top-double">
                    <label htmlFor="select-texes">
                        {getTranslatedText('extraTaxes', store)}
                    </label>
                    <Multiselect
                        options={extraTaxes}
                        onSelect={onSelectTaxes}
                        onRemove={onRemoveTaxes}
                        displayValue="name"
                        selectedValues={initialExtraTaxes ? initialExtraTaxes.filter(t => t !== null).map(t => {
                            return {
                                name: t.name,
                                value: t.id,
                            };
                        }) : []}
                    />
                </div>
                <div className="add-options margin-top-double" style={{ marginBottom: 350 }}>
                    <label htmlFor="select-texes">{getTranslatedText('addUpsells', store)}</label>
                    <Multiselect
                        options={items}
                        onSelect={onSelectUpsells}
                        onRemove={onRemoveUpsells}
                        displayValue="name"
                        selectedValues={rootCategoryItem ? rootCategoryItem.upsells?.filter(t => t !== null).map(t => {
                            return {
                                name: t.name,
                                value: t.id,
                            };
                        }) : []}
                    />
                </div>
            </div>
        </div>
    );

};

export default ProviderModifiersFilters;
