import React, { Component } from "react";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { ProtectedRoute } from "./components/authorization/protected.route";
import AddFilter from "./components/filters/add-filter";
import Filters from "./components/filters/filters";
import ImportDefaultFilters from "./components/filters/import-default-filters";
import Login from "./components/login/login";
import AddRootCategoryItem from "./components/rootCategory-item/add-rootCategory-item";
import RootCategoryItems from "./components/rootCategory-item/rootCategory-items";
import AddRootCategory from "./components/rootCategory/add-rootCategory";
import AddCategory from "./components/rootCategory/categories/add-category";
import EditCategory from "./components/rootCategory/categories/edit-category";
import AddModifier from "./components/modifiers/add-modifier";
import ImportDefaultModifiers from "./components/modifiers/import-default-modifier";
import Modifiers from "./components/modifiers/modifiers";
import ProviderInfo from "./components/provider-info/provider-info";
import WelcomeProvider from "./components/provider-info/welcome-provider";
import Providers from "./components/providers/providers";
import ProviderGroupsInfo from "./components/provider-groups/provider-groups-info";
import AddProviderGroups from "./components/provider-groups/add-provider-groups";
import EditProviderGroups from "./components/provider-groups/edit-provider-groups";
import ChooseProvider from "./components/provider-groups/choose-providers";
import TimeSettings from "./components/time-settings/time-setings";
import Welcome from "./components/welcome/welcome";
import Auth from "./components/authorization/auth";
import Loader from "./components/loader/loader";
import { store } from "./redux/store";
import Config from "./Config";
import { ToastContainer, toast } from "react-toastify";
import LocalData from "./helpers/local-data";
import jwtDecode from "jwt-decode";
import ScrollToTop from "./helpers/hcn-scroll-top";
import Coupons from "./components/coupons/Coupons";
import EditAddCoupons from "./components/coupons/Edit_Add_Coupons";
import { checkTranslations } from "./helpers/checkTranslations";
import { useDispatch } from "react-redux";

export const Context = createContext({});

function App(props) {
  const [rootCategories, setRootCategories] = useState([]);
  const [loading, setLoading] = useState(store.getState().Reducer.loading);

  const dispatch = useDispatch();

  checkTranslations(dispatch);
  useEffect(() => {
    const jwt_access_token = LocalData.get(
      Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN
    );
    const d = new Date();
    const nowTime = d.getTime();
    const token = jwt_access_token && jwtDecode(jwt_access_token);

    if (token) {
      setTimeout(() => {
        toast("WARNING!\nYou will be signed out in 2 minutes!");
      }, new Date(token.exp * 1000 - nowTime - 120000).getTime());
    }

    const unsubscribeRedux = store.subscribe(() => {
      let state = store.getState();

      if (state.Reducer.type === Config.REDUCER_TYPES.SET_LOADING) {
        setLoading(store.getState().Reducer.loading);
      }
    });

    return () => {
      unsubscribeRedux();
    };
  }, []);

  return (
    store.getState().Reducer.dinein && (
      <Context.Provider value={{ rootCategories, setRootCategories }}>
        <Loader loading={loading} />
        <ToastContainer />
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route
              path="/"
              exact={true}
              render={() => {
                if (Auth.isAuthenticated()) {
                  return <Welcome />;
                } else {
                  return <Login />;
                }
              }}
            />
            <Route
              path="/login"
              exact={true}
              render={() => {
                return <Login />;
              }}
            />
            {/* <ProtectedRoute exact path="/app" component={AppLayout} /> */}
            <ProtectedRoute
              path="/wsidebar-header flex-centerelcome"
              exact={true}
              component={Welcome}
            />
            <ProtectedRoute path="/providers" exact component={Providers} />
            <ProtectedRoute path="/provider-info/" component={ProviderInfo} />
            <ProtectedRoute
              path="/provider-group-info/"
              component={ProviderGroupsInfo}
            />
            <ProtectedRoute
              path="/add-provider-group/"
              component={AddProviderGroups}
            />
            <ProtectedRoute path="/coupons-info/" component={Coupons} />
            <ProtectedRoute path="/add-coupon/" component={EditAddCoupons} />
            <ProtectedRoute
              path="/edit-provider-group/"
              component={EditProviderGroups}
            />
            <ProtectedRoute
              path="/choose-provider/"
              component={ChooseProvider}
            />
            <ProtectedRoute path="/modifiers" exact component={Modifiers} />
            <ProtectedRoute path="/add-modifier" component={AddModifier} />
            <ProtectedRoute
              path="/import-default-filter"
              component={ImportDefaultFilters}
            />
            <ProtectedRoute
              path="/import-default-modifiers"
              component={ImportDefaultModifiers}
            />
            <ProtectedRoute path="/filters" component={Filters} />
            <ProtectedRoute
              path="/welcome-provider"
              component={WelcomeProvider}
            />
            <ProtectedRoute path="/add-filter" component={AddFilter} />
            <ProtectedRoute
              path="/add-rootCategory-item"
              component={AddRootCategoryItem}
            />
            <ProtectedRoute
              path="/rootCategory-items"
              component={RootCategoryItems}
            />
            <ProtectedRoute
              path="/add-rootCategory"
              component={AddRootCategory}
            />
            <ProtectedRoute path="/add-category" component={AddCategory} />
            <ProtectedRoute path="/edit-category" component={EditCategory} />
            <ProtectedRoute path="/time-settings" component={TimeSettings} />
          </Switch>
        </BrowserRouter>
      </Context.Provider>
    )
  );
}

export default App;
