import {isJwtExpired} from 'jwt-check-expiration';
import Config from '../../Config';
import LocalData from '../../helpers/local-data';

class Auth {
    login(cb) {
        if (LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN)) {
            cb();
        }
    }
    logout(cb) {
        LocalData.remove(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN);
        LocalData.remove(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN);
        // localStorage.clear();
        cb();
    }
    isAuthenticated() {
        let jwt_access_token = LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN);

        if (!jwt_access_token) {
            return false;
        }

        if (isJwtExpired(jwt_access_token)) {
            LocalData.remove(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN);

        }
        return !!LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN) && !isJwtExpired(LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN));
    }
}

export default new Auth();
