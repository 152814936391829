import React, { Component, useState } from 'react';
import ReactModal from 'react-modal';
import { getTranslatedText } from '../helpers/checkTranslations';
import { store } from '../redux/store';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        width: '400px',
        height: '300px',
        padding: '30px',
        borderRadius: '8px',
        backgroundColor: 'black',
        transform: 'translate(-50%,-50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    row_container: {
        flexDirection: 'column',
        marginTop: '10px',
    },
    row: {
        display: 'block',
        width: '100%',
        marginBottom: '10px',
    },
    label: {
        display: 'inline-block',
        width: '38%',
    },
};
const GenerateLinkModal = ({ show, infoMessage, onPress, hideModal }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [roomNumber, setRoomNumber] = useState('');

    const confirmAction = () => {
        if (onPress) {
            onPress({
                ...infoMessage.data,
                firstName,
                lastName,
                roomNumber: parseInt(roomNumber),
            });
        }
        hideModal();
    };

    const rejectAction = () => {
        hideModal();
    };

    return (
        <>
            <ReactModal
                isOpen={show}
                style={customStyles}
                ariaHideApp={false}>
                <>
                    <div className="flex-center">
                        {infoMessage.message}
                    </div>
                    <div style={customStyles.row_container}>
                        <label style={customStyles.row}>
                            <span style={customStyles.label}>{getTranslatedText('fName', store)}</span>
                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </label>
                        <label style={customStyles.row}>
                            <span style={customStyles.label}>{getTranslatedText('lName', store)}</span>
                            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </label>
                        <label style={customStyles.row}>
                            <span style={customStyles.label}>{getTranslatedText('roomNumber', store)}</span>
                            <input type="number" value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} />
                        </label>
                    </div>
                    <div className="modal-footer">
                        <div className="flex-center">
                            <button
                                type="button"
                                className="default-btn"

                                onClick={confirmAction}
                                data-dismiss="modal">
                                {getTranslatedText('ok', store)}
                            </button>
                            <button
                                className="clear"
                                onClick={rejectAction} type="button">
                                {getTranslatedText('cancel', store)}
                            </button>
                        </div>
                    </div>
                </>
            </ReactModal></>
    );
};
export default GenerateLinkModal;
