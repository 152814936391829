import React, { Component } from 'react';
import { useEffect, useState } from 'react';

const FilterOut = ({filters, filter, index, filtersOut, setFilters, addFilterToRawItem})=>{



    const addFilter = (id) =>{
        setFilters(
            filters.map(item => item.id === id ? {...item, selected: !item.selected} : {...item}),
        );
    };

    return (
        <div onClick={()=> addFilter(filter.id)} className={`flex-between filter-container  ${filter.selected ? 'active' : ''}`} >
            <div className="filter-symb flex-center">{filter.name.replace('-', ' ').split(' ').map(item=> item[0]?.toUpperCase()).join('')}</div>
            <div className="flex-between-1">
                <div className="filter-name">{filter.name}</div>
                <div className="filter-add">+</div>
            </div>
        </div>
    );
};
export default FilterOut;
