import React from 'react';

const HCNDatetime = {
    timezones: [
        'Pacific/Midway',
        'Pacific/Samoa',
        'Pacific/Honolulu',
        'US/Alaska',
        'America/Los_Angeles',
        'America/Tijuana',
        'US/Arizona',
        'America/Chihuahua',
        'America/Mazatlan',
        'US/Mountain',
        'America/Managua',
        'US/Central',
        'America/Mexico_City',
        'America/Monterrey',
        'Canada/Saskatchewan',
        'US/Eastern',
        'US/East-Indiana',
        'America/Lima',
        'America/Bogota',
        'Canada/Atlantic',
        'America/Caracas',
        'America/La_Paz',
        'America/Santiago',
        'Canada/Newfoundland',
        'America/Sao_Paulo',
        'America/Argentina/Buenos_Aires',
        'America/Godthab',
        'America/Noronha',
        'Atlantic/Azores',
        'Atlantic/Cape_Verde',
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'Africa/Casablanca',
        'Etc/Greenwich',
        'Europe/Lisbon',
        'Europe/London',
        'Africa/Monrovia',
        'UTC',
        'Europe/Amsterdam',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Europe/Brussels',
        'Europe/Budapest',
        'Europe/Copenhagen',
        'Europe/Ljubljana',
        'Europe/Madrid',
        'Europe/Paris',
        'Europe/Prague',
        'Europe/Rome',
        'Europe/Sarajevo',
        'Europe/Skopje',
        'Europe/Stockholm',
        'Europe/Vienna',
        'Europe/Warsaw',
        'Africa/Lagos',
        'Europe/Zagreb',
        'Europe/Athens',
        'Europe/Bucharest',
        'Africa/Cairo',
        'Africa/Harare',
        'Europe/Istanbul',
        'Asia/Jerusalem',
        'Europe/Helsinki',
        'Africa/Johannesburg',
        'Europe/Riga',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Vilnius',
        'Asia/Baghdad',
        'Asia/Kuwait',
        'Europe/Minsk',
        'Africa/Nairobi',
        'Asia/Riyadh',
        'Europe/Volgograd',
        'Asia/Tehran',
        'Asia/Baku',
        'Europe/Moscow',
        'Asia/Muscat',
        'Asia/Tbilisi',
        'Asia/Yerevan',
        'Asia/Kabul',
        'Asia/Karachi',
        'Asia/Tashkent',
        'Asia/Calcutta',
        'Asia/Kolkata',
        'Asia/Katmandu',
        'Asia/Almaty',
        'Asia/Dhaka',
        'Asia/Dhaka',
        'Asia/Yekaterinburg',
        'Asia/Rangoon',
        'Asia/Bangkok',
        'Asia/Jakarta',
        'Asia/Novosibirsk',
        'Asia/Hong_Kong',
        'Asia/Chongqing',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Australia/Perth',
        'Asia/Singapore',
        'Asia/Taipei',
        'Asia/Ulan_Bator',
        'Asia/Urumqi',
        'Asia/Irkutsk',
        'Asia/Seoul',
        'Asia/Tokyo',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Australia/Brisbane',
        'Australia/Canberra',
        'Pacific/Guam',
        'Australia/Hobart',
        'Australia/Melbourne',
        'Pacific/Port_Moresby',
        'Australia/Sydney',
        'Asia/Yakutsk',
        'Asia/Vladivostok',
        'Pacific/Fiji',
        'Pacific/Kwajalein',
        'Asia/Kamchatka',
        'Asia/Magadan',
        'Pacific/Auckland',
        'Pacific/Tongatapu',
    ],
};

export default HCNDatetime;
