import Config from '../Config';
import Services from './services';

const PromotionsModel = {
    remote_getAll: () => {
        return Services.getApi(Config.getBasePath() + '/promotions');
    },
    remote_getOne: (id) => {
        return Services.getApi(Config.getBasePath() + `/promotions/${id}`);
    },
    remote_deleteOne: (id) => {
        return Services.deleteApi( Config.getBasePath() + `/promotions/${id}`,
        );
    },
    remote_addOne: (coupon) => {
        return Services.postApi(Config.getBasePath() + '/promotions', coupon);
    },
    remote_editOne: (coupon) => {
        return Services.putApi(Config.getBasePath() + `/promotions/${coupon.id}`, coupon);

    },
}

export default PromotionsModel