import Config from '../Config';
import Services from './services';

const ProviderModel = {
    remote_getAll: () => {
        return Services.getApi(Config.getBasePath() + '/providers');
    },
    remote_getOne: (id) => {
        return Services.getApi(Config.getBasePath() + `/providers/${Config.getProviderId(id)}`);
    },
    remote_deleteOneProvider: (provider) => {
        return Services.deleteApi(Config.getBasePath() + `/providers/${provider.id}`,
        );
    },
    remote_addOne: (provider) => {
        return Services.postApi(Config.getBasePath() + '/providers', provider);
    },
    remote_editOne: (provider) => {
        return Services.putApi(Config.getBasePath() + `/providers/${provider.id}`, provider);

    },
    createProviderFilters: (providerWithFilters) => {
        return Services.postApi(Config.getBasePath() + '/provider-filters', providerWithFilters);
    },
    deleteProviderFilterById: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/provider-filters/${id}`);
    },
    getProviderGroups: () => {
        return Services.getApi(Config.getBasePath() + '/providers/groups');
    },
    createProviderGroups: (data) => {
        return Services.postApi(Config.getBasePath() + '/providers/groups', data);
    },
    updateProviderGroups: (id, data) => {
        return Services.putApi(Config.getBasePath() + `/providers/groups/${id}`, data);
    },
    deleteProviderGroups: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/providers/groups/${id}`);
    },
    generateGroupLink: (id, hotelId, room, firstName, lastName) => {
        return Services.postApi(Config.getBasePath() + `/providers/groups/${id}/generatelink`, {
            'hotelId': hotelId,
            'room': `${room}`,
            'firstName': firstName,
            'lastName': lastName,
        });
    },
    // remote_pushMessageToDevices: message => {
    //     return Services.postApi(
    //         Services.basePath() + `/socket/property/${Config.getPropertyId()}`,
    //         message,
    //     );
    // },
};

export default ProviderModel;
