import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ProviderModel from '../../data/provider-model';
import InfoModal from '../info-modal';
import { useDispatch } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const Filter = ({ filter, filters, setFilters }) => {
    const [show, setShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });
    const dispatch = useDispatch();

    const deleteFilterInHCN = () => {
        HCNLoader.show(dispatch);
        FilterModel
            .deleteFilterById(filter.id)
            .then(() => {
                setFilters(filters.filter(item => item.id !== filter.id));
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    const deleteProviderFilter = () => {
        HCNLoader.show(dispatch);
        ProviderModel
            .deleteProviderFilterById(filter.id)
            .then(() => {
                setFilters(filters.filter(item => item.id !== filter.id));
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    useEffect(() => {
        if (infoMessage.confirmation) {
            FilterModel.providerId ? deleteProviderFilter() : deleteFilterInHCN();
        }

        return () => {
            setInfoMessage({});
        };
    }, [infoMessage.confirmation]);

    const hideModal = () => {
        setShow(false);
    };

    const deleteFilter = () => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteFilterWarning', store),
        });
    };

    return (
        <div className="row flex-between add-row-action" >
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show} />
            <div>
                <Link to={`/add-filter/${filter.id}/${filter.name}/${filter.acronym}`}><h2>{filter.name}</h2></Link>
            </div>
            <div className="flex-start">
                <Link to={`/add-filter/${filter.name}/${filter.acronym}`}>
                    <button className="copy">
                        <img src="../img/svg/duplicate.svg" alt="double item" />

                    </button>
                </Link>

                <button onClick={deleteFilter} className="remove">
                    <img src="../img/svg/trash.svg" alt="double item" />
                </button>
            </div>
        </div>
    );
};

export default Filter;
