import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import "./tabs.css";
import HCNDatetime from "../../../helpers/hcn-datetime";
import DropZone from "../../drop-zone/drop-zone";
import Services from "../../../data/services";
import UploadModel from "../../../data/upload-model";
import HCNLoader from "../../../helpers/hcn-loader";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import HCNImages from "../../../helpers/hcn-images";
import Config from "../../../Config";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { store } from "../../../redux/store";
import { getTranslatedText } from "../../../helpers/checkTranslations";
import { validateEmails } from "../../../utils/functions";

const FirstTab = ({ providerForm, setProviderForm, setModified }) => {
  const [initialValue, setInitialValue] = useState();
  const [uploadedFileName, setUploadedFileName] = useState({});
  const [initialTips, setInitialTips] = useState(null);
  const [tips, setTips] = useState(
    Array(100)
      .fill()
      .map((item, idx) => ({
        name: idx + 1,
        value: idx + 1,
      }))
  );
  const [isDineIn, setSMth] = useState(Config.getDineIn());

  const defaultImageUploadRef = useRef();
  const mainImageUploadRef = useRef();
  const thumbnailImageUploadRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialValue) {
      setInitialValue(providerForm.info);
    }
    setInitialTips(providerForm.tips);
  }, [providerForm.info]);

  const editorRef = useRef(null);

  const onFilesUpload = (files, size) => {
    if (files && files.length > 0) {
      setModified(true);
      const formData = new FormData();
      let filename = `${uuidv4()}.${files[0].name.split(".").pop()}`;
      formData.append("file", files[0], filename);
      formData.append("name", filename);

      HCNLoader.show(dispatch);
      UploadModel.upload(formData)
        .then(() => {
          HCNLoader.hide(dispatch);
          setUploadedFileName({
            ...uploadedFileName,
            [size]: filename,
          });
          providerForm.images = HCNImages.updateImageObject(
            providerForm.images,
            size,
            filename
          );
          setProviderForm({ ...providerForm });
        })
        .catch((err) => {
          toast(err.message);
          HCNLoader.hide(dispatch);
        });
    } else {
      console.log("err");
    }
  };

  const onSelectModifier = (list) => {
    setProviderForm({
      ...providerForm,
      tips: list.map((item) => item.value),
    });
  };

  const onRemoveModifier = (list) => {
    setProviderForm({
      ...providerForm,
      tips: list.map((item) => item.value),
    });
  };

  const deleteImage = (size) => {
    setProviderForm({
      ...providerForm,
      images:
        size === "default"
          ? [
              {
                ...(providerForm.images[0]?.main && {
                  main: providerForm.images[0]?.main,
                }),
                ...(providerForm.images[0]?.thumbnail && {
                  thumbnail: providerForm.images[0]?.thumbnail,
                }),
              },
            ]
          : size === "main"
          ? [
              {
                ...(providerForm.images[0]?.default && {
                  main: providerForm.images[0]?.default,
                }),
                ...(providerForm.images[0]?.thumbnail && {
                  thumbnail: providerForm.images[0]?.thumbnail,
                }),
              },
            ]
          : size === "thumbnail"
          ? [
              {
                ...(providerForm.images[0]?.main && {
                  main: providerForm.images[0]?.main,
                }),
                ...(providerForm.images[0]?.default && {
                  thumbnail: providerForm.images[0]?.default,
                }),
              },
            ]
          : [],
    });
  };

	

  return (
    <>
      <div id="content_rest_info" className="inner-content">
        <div className="info info-title" data-info="rest_name">
          <label htmlFor="provider_name">
            {getTranslatedText("providerName", store)}
            <sup>(*)</sup>
          </label>
          <input
            value={providerForm.name}
            onChange={(event) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                name: event.target.value,
              });
            }}
            id="provider_name"
            name="provider_name"
            type="text"
          />
        </div>
        {isDineIn && (
          <div className="info info-title" data-info="rest_type">
            <label htmlFor="provider_type">
              {getTranslatedText("providerType", store)}
              <sup>(*)</sup>
            </label>
            <select
              id="provider_type"
              name="provider_type"
              value={providerForm.type}
              onChange={(event) => {
                setModified(true);
                setProviderForm({
                  ...providerForm,
                  type: event.target.value,
                });
              }}>
              <option disabled defaultValue></option>
              <option value="0">{getTranslatedText("american", store)}</option>
              <option value="28">{getTranslatedText("asian", store)}</option>
              <option value="1">{getTranslatedText("canadian", store)}</option>
              <option value="2">{getTranslatedText("chinese", store)}</option>
              <option value="3">
                {getTranslatedText("continental", store)}
              </option>
              <option value="4">{getTranslatedText("cuban", store)}</option>
              <option value="5">{getTranslatedText("french", store)}</option>
              <option value="6">{getTranslatedText("german", store)}</option>
              <option value="7">{getTranslatedText("greek", store)}</option>
              <option value="8">{getTranslatedText("indian", store)}</option>
              <option value="9">
                {getTranslatedText("indonesian", store)}
              </option>
              <option value="10">{getTranslatedText("italian", store)}</option>
              <option value="11">{getTranslatedText("japanese", store)}</option>
              <option value="12">{getTranslatedText("korean", store)}</option>
              <option value="13">{getTranslatedText("lebanese", store)}</option>
              <option value="29">
                {getTranslatedText("liquorStore", store)}
              </option>
              <option value="14">
                {getTranslatedText("malaysian", store)}
              </option>
              <option value="15">
                {getTranslatedText("mediterranean", store)}
              </option>
              <option value="16">{getTranslatedText("mexican", store)}</option>
              <option value="17">{getTranslatedText("moroccan", store)}</option>
              <option value="18">{getTranslatedText("nigerian", store)}</option>
              <option value="19">{getTranslatedText("pub", store)}</option>
              <option value="20">{getTranslatedText("russian", store)}</option>
              <option value="21">
                {getTranslatedText("singaporean", store)}
              </option>
              <option value="22">
                {getTranslatedText("southAfrican", store)}
              </option>
              <option value="23">{getTranslatedText("spanish", store)}</option>
              <option value="30">
                {getTranslatedText("steakhouse", store)}
              </option>
              <option value="24">{getTranslatedText("thai", store)}</option>
              <option value="25">{getTranslatedText("tibetan", store)}</option>
              <option value="26">{getTranslatedText("turkish", store)}</option>
              <option value="27">
                {getTranslatedText("vietnamese", store)}
              </option>
            </select>
          </div>
        )}
        <div className="info info-title" data-info="rest_type">
          <label htmlFor="provider_type">
            {getTranslatedText("providerComm", store)}
            <sup>(*)</sup>
          </label>
          <select
            id="provider_comm"
            name="provider_comm"
            value={providerForm.communicationType}
            onChange={(event) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                communicationType: Number(event.target.value),
              });
            }}>
            <option value="0">{getTranslatedText("webSockets", store)}</option>
            <option value="1">
              {getTranslatedText("pushNotifications", store)}
            </option>
            <option value="2">{getTranslatedText("emails", store)}</option>
          </select>
        </div>
        {providerForm.communicationType === 2 && (
          <div className="info info-title" data-info="rest_type">
            <label htmlFor="provider_type">
              {getTranslatedText("email", store)}
              <sup>(*)</sup>
            </label>
            <input
              id="provider_comm"
              name="provider_comm"
              value={providerForm.meta.email}
              onChange={(event) => {
                setModified(true);
                setProviderForm({
                  ...providerForm,
                  meta: { ...providerForm.meta, email: event.target.value },
                });
              }}
              onBlur={(ev) => {
								const valid = validateEmails(ev.target.value)
								if (!valid) {
									toast(
										"You have to add a proper type of email! Ex. john@doe.com"
									);
								}
							}}
            />
          </div>
        )}
        <div className="info" data-info="rest_teaser">
          <label htmlFor="provider_teaser">
            {getTranslatedText("teaser", store)}
            <sup>(*)</sup>
          </label>
          <input
            id="provider_teaser"
            name="provider_teaser"
            value={providerForm.teaser}
            onChange={(event) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                teaser: event.target.value,
              });
            }}
            type="text"
          />
        </div>
        <div className="info" data-info="rest_timezone">
          <label htmlFor="provider_timezone">
            {getTranslatedText("timezone", store)}
            <sup>(*)</sup>
          </label>
          <select
            id="provider_timezone"
            name="provider_timezone"
            value={providerForm.timezone}
            onChange={(event) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                timezone: event.target.value,
              });
            }}>
            <option value=""></option>
            {HCNDatetime.timezones.sort().map((tz, idx) => (
              <option key={idx} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
        <div
          className="info"
          style={{ width: 190 }}
          data-info="rest_deliverytime">
          <label htmlFor="provider_timezone">
            {getTranslatedText("deliveryTime", store)}
            <sup>(*)</sup>
          </label>
          <input
            id="provider_deliverytime"
            name="provider_deliverytime"
            type="number"
            value={
              Math.abs(providerForm.deliveryTime) === 0
                ? ""
                : Math.abs(providerForm.deliveryTime)
            }
            min={0}
            max={150}
            onChange={(event) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                deliveryTime:
                  event.target.value > 150 ? 150 : event.target.value,
              });
            }}
          />
        </div>

        <div
          style={{ width: 190, display: "flex", width: 200, marginBottom: 20 }}
          data-info="rest_deliverytime">
          <label htmlFor="provider_timezone">
            {getTranslatedText("paymentRequired", store)}
          </label>
          <input
            style={{ marginLeft: 20 }}
            type="checkbox"
            defaultChecked={providerForm.meta?.paymentRequired}
            onClick={(event) => {
              setProviderForm({
                ...providerForm,
                meta: {
                  ...providerForm.meta,
                  paymentRequired: event.target.checked,
                },
              });
            }}
          />
        </div>
        <div
          style={{ width: 190, display: "flex", width: 200, marginBottom: 20 }}
          data-info="rest_deliverytime">
          <label htmlFor="provider_timezone">
            {getTranslatedText("selfDelivered", store)}
          </label>
          <input
            style={{ marginLeft: 20 }}
            type="checkbox"
            defaultChecked={providerForm.meta?.selfDelivered}
            onClick={(event) => {
              setProviderForm({
                ...providerForm,
                meta: {
                  ...providerForm.meta,
                  selfDelivered: event.target.checked,
                },
              });
            }}
          />
        </div>
        <div className="info" data-info="rest_tips">
          <label htmlFor="provider_timezone">
            {getTranslatedText("tips", store)}
          </label>
          <Multiselect
            options={tips}
            onSelect={onSelectModifier}
            onRemove={onRemoveModifier}
            displayValue="name"
            placeholder={
              providerForm.tips.length === 0
                ? getTranslatedText("select", store)
                : ""
            }
            selectedValues={
              initialTips
                ? initialTips
                    .filter((t) => t !== null)
                    .map((t) => {
                      return {
                        name: t,
                        value: t,
                      };
                    })
                : []
            }
          />
        </div>
        <div className="info" data-info="rest_info">
          <label htmlFor="provider_info">
            {getTranslatedText("providerInfo", store)}
            <sup>(*)</sup>
          </label>

          <Editor
            apiKey={Config.getTinyMCEApiKey()}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            value={providerForm.info}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={(content, editor) => {
              setModified(true);
              setProviderForm({
                ...providerForm,
                info: content,
              });
            }}
          />
        </div>
        <div style={{ fontWeight: 500 }} data-info="rest_info">
          <label htmlFor="provider_info">
            {getTranslatedText("placeSettings", store)}
          </label>
          <input
            style={{ marginLeft: 20 }}
            type="checkbox"
            defaultChecked={providerForm.placeSettings}
            checked={providerForm.placeSettings}
            onClick={(event) =>
              setProviderForm({
                ...providerForm,
                placeSettings: event.target.checked,
              })
            }
          />
        </div>
        {Object.values(Config.IMAGE_SIZES).map((size, index) => (
          <div className="upload" key={index}>
            <label>
              <h3>
                {size === Config.IMAGE_SIZES.DEFAULT
                  ? getTranslatedText("defaultImage", store)
                  : size === Config.IMAGE_SIZES.MAIN
                  ? getTranslatedText("mainImage", store)
                  : getTranslatedText("thumbnailImage", store)}
              </h3>
            </label>
            {providerForm &&
              providerForm.images &&
              providerForm.images.length > 0 &&
              providerForm.images[0][size] && (
                <div className="uploaded-photo">
                  <div className="grid-flex">
                    <img
                      src={HCNImages.getFullImageURL(
                        providerForm.images[0],
                        size
                      )}
                      width="200"
                    />
                    <button
                      className="remove margin-left-auto"
                      onClick={() => deleteImage(size)}>
                      <img src="../img/svg/trash.svg" alt="double item" />
                    </button>
                  </div>
                </div>
              )}
            {!providerForm.images[0]?.[size] && (
              <DropZone
                accepted_mime_types={"image/gif,image/jpeg,image/png,image/jpg"}
                text={getTranslatedText("dragDrop", store)}
                multiple={false}
                onFilesUpload={(files) => onFilesUpload(files, size)}
                ref={
                  size === Config.IMAGE_SIZES.DEFAULT
                    ? defaultImageUploadRef
                    : Config.IMAGE_SIZES.MAIN
                    ? mainImageUploadRef
                    : thumbnailImageUploadRef
                }
              />
            )}
          </div>
        ))}
        <div style={{ fontWeight: 500, marginTop: 20 }} data-info="rest_info">
          <label htmlFor="provider_info">
            {getTranslatedText("DEFAULTIMAGE", store)}
          </label>
          <input
            style={{ marginLeft: 20 }}
            type="checkbox"
            defaultChecked={providerForm.placeSettings}
            checked={providerForm.placeSettings}
            onClick={(event) =>
              setProviderForm({
                ...providerForm,
                placeSettings: event.target.checked,
              })
            }
          />
        </div>
      </div>
    </>
  );
};
export default FirstTab;
