import gitInfo from "react-git-info/macro";
const GitInfo = gitInfo();

const hash = GitInfo.commit.shortHash;
const Config = {
  LOCAL_DATA_KEYS: {
    ACCESS_TOKEN: "hcn-dinein-admin-access-token",
    JWT_ACCESS_TOKEN: "hcn-dinein-admin-jwt-access-token",
    PROVIDER_ID: "hcn-dinein-admin-provider_id",
    PROVIDER: "hcn-dinein-admin-provider",
  },
  ROLES: {
    ADMIN: "admin",
  },
  REDUCER_TYPES: {
    SET_LOADING: "SET_LOADING",
    SET_DINEIN: "SET_DINEIN",
    SET_CATEGORY: "SET_CATEGORY",
    SET_IS_ADMIN: "SET_IS_ADMIN",
  },
  TIME_SETTINGS_MODE: {
    DEFAULT: "DEFAULT",
    SPECIAL: "SPECIAL",
  },
  IMAGE_SIZES: {
    DEFAULT: "default",
    MAIN: "main",
    THUMBNAIL: "thumbnail",
  },

  getAccessTokenUsername: () => {
    return "dinein-admin-ui";
  },
  getAccessTokenPassword: () => {
    return "T71DHRCkDOHgu7LkajDgdRP9E9psVoBJtc7WIruNBfdM3QzTubRRgpnp5g9GeRBO";
  },
  getBasePath: () => {
    return process.env.REACT_APP_API_PATH_DINEIN_BACKEND;
  },
  getBaseAdminPath: () => {
    return process.env.REACT_APP_API_PATH_ADMIN_BACKEND;
  },
  getAuthBasePath: () => {
    return process.env.REACT_APP_API_PATH_AUTH_BACKEND;
  },
  getDineIn: () => {
    return process.env.REACT_APP_DINEIN === "true";
  },
  getProviderId: (id) => {
    return id;
  },
  getVersion: () => {
    return hash;
  },
  getS3BucketPrefix: () => {
    return process.env.REACT_APP_S3_BUCKET_PREFIX;
  },
  getTinyMCEApiKey: () => {
    return "npntgnd8ki5n14etsdl7blwcdp20pxw53fbe66bxp33rowuo";
  },
  getHotelPath: () => {
    return process.env.REACT_APP_API_PATH_NAVIGATOR_BACKEND;
  },
};

export default Config;
