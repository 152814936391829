import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import ItemModel from '../../data/item-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import FilterOut from './filter-out';
import './modifier.css';
const RawItemModal = ({
    filters,
    index,
    setFilters,
    rawItem,
    setModifier,
    modifier,
    hideModal,
}) => {
    useEffect(() => {
        setFilters(
            filters.map(item => {
                if (
                    rawItem.filtersOut && rawItem.filtersOut.length &&
                    rawItem.filtersOut.find(it => it.id === item.id)
                ) {
                    return { ...item, selected: true };
                }
                return { ...item, selected: false };
            }),
        );
    }, []);



    const addFiltersToRawItem = () => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.map((it, idx) =>
                idx === index ? { ...it, filtersOut: filters.filter(item => item.selected).map(item => item) } : { ...it },
            ),
        });

        hideModal();
    };

    const selectAll = () => {
        setFilters(filters.map(f => ({ ...f, selected: true })))
    };
    const clearAll = () => {
        setFilters(filters.map(f => ({ ...f, selected: false })))
    };


    return (
        <div
            id="modal-add-option"
            data-attribute="modal-window"
            className="modal-container">
            <div className="modal-wrapper filter-modal">
                <button
                    onClick={hideModal}
                    type="button"
                    className="close-modal"
                    data-dismiss="modal">
                    x
                </button>
                <div className="main-content">
                    <div className="select-buttons center">
                        <button type="button" className="select-all" onClick={selectAll}>
                            {getTranslatedText('selectAll', store)}
                        </button>
                        <button type="button" className="clear-all" onClick={clearAll}>
                            {getTranslatedText('clearAll', store)}
                        </button>
                    </div>
                    <div className="fliters-container wrap-items">
                        {filters.map((filter, index) =>
                            <FilterOut
                                key={filter.id}
                                index={index}
                                filter={filter}
                                filters={filters}
                                setFilters={setFilters}
                                filtersOut={rawItem.filtersOut ? rawItem.filtersOut.concat(filter) : []}
                            />,
                        )}
                    </div>
                    <div className="flex-between end">
                        <button
                            onClick={addFiltersToRawItem}
                            className="btn default-btn"
                        >
                            {getTranslatedText('save', store)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RawItemModal;
