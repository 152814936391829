import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import { default as plus } from '../plus-sign.svg';
import './time-settings.css';
const TimeSettings = props => {
    return (
        <div className="sidebar-component">
            <div className="flex-between">
                <Link to="/time-settings">
                    <p data-component="provider">
                        {getTranslatedText('timeSettings', store)}
                    </p>
                </Link>
                {/* <Link to="#" onClick={props.addClicked}>
                    <button className="btn add-btn">
                        <span>
                            <img src={plus} alt="add" />
                        </span>
                    </button>
                </Link> */}
            </div>
        </div>
    );
};
export default TimeSettings;
