import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getTranslatedText } from "../../../helpers/checkTranslations";
import { store } from "../../../redux/store";
import { default as plus } from "../plus-sign.svg";
const SidebarProviders = () => {
  return (
    <>
      <Link to="/providers">
        <p data-component="provider">{getTranslatedText("providers", store)}</p>
      </Link>
      {store.getState().Reducer.isAdmin && (
        <Link to="/provider-info">
          <button className="btn add-btn">
            <span>
              <img src={plus} alt="add" />
            </span>
          </button>
        </Link>
      )}
    </>
  );
};

export default SidebarProviders;
