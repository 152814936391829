import React, { Component, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RootCategoryCategory from './rootCategory-category';
import './rootCategory.css';

const RootCategoryCategories = ({ rootCategory, setRootCategoryCategoryItems, rootCategoryCategoryItems, setSelectedCategory, selectedCategory, drag }) => {

    const [draggedList, updateDraggedList] = useState(null);

    useEffect(() => {
        updateDraggedList(rootCategoryCategoryItems);
    }, [rootCategoryCategoryItems]);

    const handleOnDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(draggedList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateDraggedList(items);
        setRootCategoryCategoryItems(items);

    };

    return (
        <div className="carousel tab-carousel" data-flickity='{
        "cellAlign": "left",
        "contain": true,
        "autoPlay": false,
        "imagesLoaded": true,
        "wrapAround": false,
        "pageDots": false,
        "prevNextButtons": false
        }'>
            {drag ? <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='rootCategorySections'>
                    {provided =>
                        <div {...provided.droppableProps} ref={provided.innerRef} >
                            {rootCategoryCategoryItems.map((categoryItem, index) =>
                                <Draggable key={index} index={index} draggableId={`${categoryItem.name}${categoryItem.id}`}>
                                    {provided =>
                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="grid-flex category-btn-items">
                                            <RootCategoryCategory
                                                rootCategoryCategoryItems={rootCategoryCategoryItems}
                                                setRootCategoryCategoryItems={setRootCategoryCategoryItems}
                                                key={categoryItem.id}
                                                setSelectedCategory={setSelectedCategory}
                                                selectedCategory={selectedCategory}
                                                categoryItem={categoryItem}
                                                drag={drag}
                                            />
                                        </div>
                                    }
                                </Draggable>,
                            )}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
                : rootCategoryCategoryItems.map(categoryItem =>
                    <RootCategoryCategory
                        rootCategoryCategoryItems={rootCategoryCategoryItems}
                        setRootCategoryCategoryItems={setRootCategoryCategoryItems}
                        key={categoryItem.id}
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                        categoryItem={categoryItem}
                        drag={drag}
                    />,
                )
            }
        </div>
    );
};

export default RootCategoryCategories;
