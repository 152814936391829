import React, { Component } from 'react';
import ExtraTaxes from '../extraTaxe/extraTaxes';
import Fees from '../fee/fees';
import Taxes from '../taxe/taxes';

const SecondTab = ({ providerForm, setProviderForm }) => {

    return (
        <div
            id="content_fees"
            className="inner-content"
        >
            <article className="fees-block">
                <Fees
                    fees={
                        providerForm.fees.fees
                            ? providerForm.fees.fees
                            : []
                    }
                    providerForm={providerForm}
                    setProviderForm={setProviderForm}
                />
            </article>

            <article className="fees-block">
                <Taxes
                    taxes={
                        providerForm.fees.fees
                            ? providerForm.fees.fees
                            : []
                    }
                    providerForm={providerForm}
                    setProviderForm={setProviderForm}
                />
            </article>

            <article className="fees-block">
                <ExtraTaxes
                    extrataxes={
                        providerForm.fees
                            .extraTaxes
                            ? providerForm.fees.extraTaxes
                            : []
                    }
                    providerForm={providerForm}
                    setProviderForm={setProviderForm}
                />
            </article>
        </div>
    );
};

export default SecondTab;
