import React, { Component } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import Fee from './fee';
import FeeModal from './feeContentModal';
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};
const Fees = ({ fees, providerForm, setProviderForm }) => {
    const [show, setShow] = useState(false);

    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };
    return (
        <>
            <div style={{ marginBottom: 12 }} className="fees-head flex-between">
                <h2>{getTranslatedText('fees', store)} <sup>(*)</sup></h2>
                <ReactModal
                    isOpen={show}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Minimal Modal Example"

                >
                    <FeeModal setProviderForm={setProviderForm} providerForm={providerForm} hideModal={hideModal} />

                </ReactModal>
                <button
                    type="button"
                    className="default-btn"
                    data-attribute="modal-action"
                    data-type="modal-add-fee"
                    onClick={showModal}
                >
                    {getTranslatedText('addFee', store)}
                </button>
            </div>
            {fees && fees.length > 0 && <div className="fees-content">
                <div className="fees-row-head">
                    <span className="col-3">{getTranslatedText('name', store)}</span>
                    <span className="col-3 text-center">{getTranslatedText('costPercentage', store)}</span>
                    <span className="col-3 text-center">{getTranslatedText('attribute', store)}</span>
                </div>
                {fees.length > 0 ? fees.map((fee, index) =>
                    <Fee id={index} providerForm={providerForm} setProviderForm={setProviderForm} key={index} fee={fee} />,
                ) : []}
            </div>}
        </>
    );
};
export default Fees;
