import React, { Component, useEffect } from 'react';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Context } from '../../../App';
import FilterModel from '../../../data/filter-model';
import RootCategoryModel from '../../../data/rootCategory-model';
import { default as plus } from '../plus-sign.svg';
import RootCategoryItem from './rootCategory-item';
import './rootCategory.css';
import HCNLoader from '../../../helpers/hcn-loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { store } from '../../../redux/store';
import { getTranslatedText } from '../../../helpers/checkTranslations';

const RootCategory = () => {
    const context = useContext(Context);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getAllRootCategorys(true);
    }, []);

    const getAllRootCategorys = (initialLoad) => {
        HCNLoader.show(dispatch);
        RootCategoryModel.getAllRootCategorys(FilterModel.providerId)
            .then(res => {
                let rootCategories = res.map(item => {
                    return { ...item, selected: false };
                });
                context.setRootCategories(rootCategories);
                HCNLoader.hide(dispatch);
                if (res.length === 0 && !initialLoad) {
                    history.push('/add-rootCategory');
                }
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    const handleOnDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(context.rootCategories);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        context.setRootCategories(items)

        setTimeout(() => {
            RootCategoryModel.reorder(FilterModel.providerId, items.map(i => i.id))
                .then(res => console.log({ res }))
                .catch(err => console.log(err))
        }, 500);
    };


    return (
        <div className="sidebar-component">
            <div className="flex-between">
                {/* <Link onClick={() => getAllRootCategorys(false)} to="#"> */}
                <p data-component="provider">
                    {getTranslatedText('rootCategory', store)}
                </p>
                {/* </Link> */}
                <Link to="/add-rootCategory">
                    <button className="btn add-btn">
                        <span>
                            <img src={plus} alt="add" />
                        </span>
                    </button>
                </Link>
            </div>
            <ul className={`categories ${context.rootCategories && context.rootCategories.length > 0 ? 'displayed' : 'dissapear'}`}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId='rootCategories'>
                        {provided =>
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {context.rootCategories && context.rootCategories.map((rootCategory, index) =>
                                    <Draggable key={index} index={index} draggableId={rootCategory.id}>
                                        {provided =>
                                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <RootCategoryItem key={rootCategory.id} rootCategory={rootCategory} />
                                            </div>
                                        }
                                    </Draggable>,
                                )}
                            </div>
                        }
                    </Droppable>
                </DragDropContext>
            </ul>
        </div>
    );
};
export default RootCategory;
