import React, { Component, useEffect } from 'react';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const AddModifierProviderFields = ({ modifier, setModifier }) => {

    return (
        <>
            <div className="buttons-on-top choose-btn-container">
                <button type="button"
                    className={`gray-btn choose-btn  ${modifier.required ? 'is-active' : ''}`}
                    onClick={() => {
                        setModifier({
                            ...modifier,
                            required: true,
                        });
                    }}
                >
                    <span>
                        {getTranslatedText('required', store)}
                    </span>
                </button>
                <button type="button"
                    className={`gray-btn choose-btn margin-left ${!modifier.required ? 'is-active' : ''}`}
                    onClick={() => {
                        setModifier({
                            ...modifier,
                            required: false,
                        });
                    }}
                >
                    <span>{getTranslatedText('optional', store)}</span>
                </button>
            </div>
            <div className="buttons-on-top choose-btn-container">
                <button type="button"
                    onClick={() => {
                        setModifier({
                            ...modifier,
                            type: 0,
                            max: 0,
                            min: 0,
                        });
                    }}
                    className={`gray-btn choose-btn  ${modifier.type === 0 ? 'is-active' : ''}`}

                >
                    <span>{getTranslatedText('selectOne', store)}</span>
                </button>
                <button type="button"

                    onClick={() => {
                        setModifier({
                            ...modifier,
                            type: 1,
                            min: 0,
                            max: 1,
                        });
                    }}
                    className={`gray-btn choose-btn margin-left ${modifier.type === 1 ? 'is-active' : ''}`}
                >
                    <span>{getTranslatedText('selectMultiple', store)}</span>
                </button>
                {
                    modifier && modifier.type === 1 ? <>
                        <div className="amount margin-left">
                            <label htmlFor="min-amount" className="margin-right-half">
                                {getTranslatedText('min', store)}
                            </label>
                            <input
                                style={{ width: 50 }}
                                type="number"
                                min={0}
                                max={modifier.rawItems.length
                                    ? modifier.rawItems.length - 1
                                    : 100}
                                id="min-amount"
                                value={modifier.min < 0 ? 0 : modifier.min}
                                onChange={event => {
                                    const regex = /[0-9]/g
                                    event.target.value &&
                                        setModifier({
                                            ...modifier,
                                            min: event.target.value.includes('.') || event.target.value.includes(',')
                                                ? ''
                                                : event.target.value > 1000
                                                    ? 1000
                                                    : Number(event.target.value),
                                        })
                                }
                                }
                            />
                            <label htmlFor="max-amount" className="margin-right-half">
                                {getTranslatedText('max', store)}
                            </label>
                            <input
                                style={{ width: 50 }}
                                // disabled={modifier.min}
                                type="number"
                                min={1}
                                max={modifier.rawItems.length
                                    ? modifier.rawItems.length
                                    : 100}
                                id="max-amount"
                                value={!modifier.min && !modifier.max ? 2 : modifier.min && !modifier.max ? modifier.min + 1 : modifier.max}
                                onChange={event => {
                                    const regex = /[0-9]/g
                                    regex.test(event.target.value) &&
                                        setModifier({
                                            ...modifier,
                                            max: event.target.value.includes('.') || event.target.value.includes(',')
                                                ? ''
                                                : event.target.value <= modifier.min
                                                    ? ''
                                                    : event.target.value > 1000
                                                        ? 1000
                                                        : Number(event.target.value),
                                        })
                                }
                                }
                            />
                        </div>
                    </> : <></>
                }

            </div>
        </>
    );
};

export default AddModifierProviderFields;
