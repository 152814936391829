import React, { Component } from "react";
import FilterModel from "../../data/filter-model";
import Filters from "./filters/filters";
import HCN_logo from "./hcn-logo";
import Items from "./items/items";
import RootCategory from "./rootCategory/rootCategory";
import Modifiers from "./modifiers/modifiers";
import SidebarProvider from "./provider/side-bar-provider";
import SidebarProviderGroups from "./provider-groups/provider-groups";
import SidebarProviders from "./provider/side-bar-providers";
import TimeSettings from "./time-settings/time-settings";
import Coupons from "./coupons/Coupons";
import { store } from "../../redux/store";

const SideBar = (props) => {
  const setDefaultId = () => {
    FilterModel.providerId = undefined;
    FilterModel.provider = undefined;
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header flex-center">
        {FilterModel.providerId ? (
          <h1>{FilterModel.provider.name}</h1>
        ) : (
          <HCN_logo />
        )}
      </div>
      <div className="sidebar-main">
        <div className="sidebar-component">
          <div className="flex-between">
            {FilterModel.providerId ? (
              <SidebarProvider />
            ) : (
              <SidebarProviders />
            )}
          </div>
        </div>

        {!FilterModel.providerId && store.getState().Reducer.isAdmin && (
          <div className="sidebar-component">
            <div className="flex-between">
              <SidebarProviderGroups providerId={FilterModel.providerId} />
            </div>
          </div>
        )}

        {(FilterModel.providerId || store.getState().Reducer.isAdmin) && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Filters providerId={FilterModel.providerId} />
            </div>
          </div>
        )}

        {(FilterModel.providerId || store.getState().Reducer.isAdmin) && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Modifiers providerId={FilterModel.providerId} />
            </div>
          </div>
        )}

        {!FilterModel.providerId && store.getState().Reducer.isAdmin && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Coupons />
            </div>
          </div>
        )}

        {FilterModel.providerId ? <Items /> : null}
        {FilterModel.providerId ? <RootCategory /> : null}
        {FilterModel.providerId ? (
          <TimeSettings addClicked={props.timeSettingsAddClicked} />
        ) : null}
      </div>
      <div
        onClick={setDefaultId}
        className="flex-center sidebar-site-logo margin-top-double">
        {FilterModel.providerId ? <HCN_logo /> : null}
      </div>
    </div>
  );
};

export default SideBar;
