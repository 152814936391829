import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { getTranslatedText } from '../helpers/checkTranslations';
import { store } from '../redux/store';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        width: '400px',
        height: '300px',
        padding: '30px',
        borderRadius: '8px',
        backgroundColor: 'black',
        transform: 'translate(-50%,-50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
};
const InfoModal = ({ show, infoMessage, setInfoMessage, hideModal }) => {
    const confirmAction = () => {
        setInfoMessage({
            ...infoMessage,
            confirmation: true,
        });
        hideModal();
    };

    const rejectAction = () => {

        setInfoMessage({
            ...infoMessage,
            confirmation: false,
        });
        hideModal();

    };

    return (
        <>
            <ReactModal
                isOpen={show}
                style={customStyles}
                ariaHideApp={false}>
                <>
                    <div className="flex-center">
                        {infoMessage.message}
                    </div>
                    <div className="modal-footer">
                        <div className="flex-center">
                            <button
                                type="button"
                                className="default-btn"

                                onClick={confirmAction}
                                data-dismiss="modal">
                                {getTranslatedText('ok', store)}
                            </button>
                            <button
                                className="clear"
                                onClick={rejectAction} type="button">
                                {getTranslatedText('cancel', store)}
                            </button>
                        </div>
                    </div>
                </>
            </ReactModal></>
    );
};
export default InfoModal;
