import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useState } from 'react';
import './taxe.css';
import { store } from '../../../redux/store';
import { getTranslatedText } from '../../../helpers/checkTranslations';
const TaxesModal = ({ taxe, hideModal, updateTaxe, setUpdateTaxe, id, setProviderForm, providerForm }) => {

    const [taxeForm, setTaxeForm] = useState({ name: taxe ? taxe.name : '', percentage: taxe ? taxe.percentage : 0, appliedTo: taxe ? taxe.appliedTo : [] });
    const [showError, setShowError] = useState(false)

    const updatedItem = (array, fee) => {
        return array.map((item, index) => {
            if (index === id) {
                return fee;
            }
            return item;
        });
    };

    const addtax = () => {
        if (taxeForm.name && taxeForm.percentage) {
            setProviderForm({
                ...providerForm,
                fees: {
                    fees: providerForm.fees.fees,
                    taxes: providerForm.fees.taxes && providerForm.fees.taxes.length > 0 && updateTaxe
                        ? updatedItem(providerForm.fees.taxes, taxeForm)
                        : providerForm.fees.taxes && providerForm.fees.taxes.length > 0 && !updateTaxe
                            ? providerForm.fees.taxes.concat(taxeForm)
                            : [{ ...taxeForm }],
                    extraTaxes: providerForm.fees.extraTaxes,
                },
            });
            if (updateTaxe) {
                setUpdateTaxe(false);
            }
            hideModal();
        } else {
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
            }, 4000);
        }
    };
    const onSelectItem = (list) => {
        setTaxeForm({
            ...taxeForm,
            appliedTo: list.map(item => item.name),
        });

    };
    const onRemoveItem = (list) => {
        setTaxeForm({
            ...taxeForm,
            appliedTo: list.map(item => item.name),
        });

    };
    const selectedValues = () => {
        let selectedValues = [];
        if (taxeForm.appliedTo.length > 0) {
            selectedValues = providerForm.fees.fees.filter(item => taxeForm.appliedTo.includes(item.name));
        }
        return selectedValues;
    };
    return (
        <div
            id="modal-add-tax"
            data-attribute="modal-window"
            className="modal-container">
            <div className="modal-wrapper">
                <button
                    type="button"
                    className="close-modal"
                    onClick={hideModal}
                    data-dismiss="modal">
                    x
                </button>
                <div className="modal-head">
                    <p className="modal-title">
                        {getTranslatedText('addtax', store)}
                    </p>
                </div>
                <div className="modal-body">
                    <div className="info">
                        <label htmlFor="identifier-tax">
                            {getTranslatedText('name', store)}
                            <sup>(*)</sup>
                        </label>
                        <input
                            id="identifier-tax"
                            name="identifier-tax"
                            type="text"
                            value={taxeForm.name}
                            onChange={event =>
                                setTaxeForm({
                                    ...taxeForm,
                                    name: event.target.value,
                                })}
                        />
                    </div>
                    <div className="info">
                        <label htmlFor="value-1-tax">
                            {getTranslatedText('percentage', store)}
                            <sup>(*)</sup>
                        </label>
                        <input
                            id="value-1-tax"
                            name="value-1-tax"
                            type="number"

                            value={Math.abs(taxeForm.percentage) === 0 ? '' : Math.abs(taxeForm.percentage)}
                            defaultValue=''
                            onChange={event =>
                                setTaxeForm({
                                    ...taxeForm,
                                    percentage: event.target.value > 100 ? 100 : event.target.value,
                                })}
                        />
                    </div>
                    <div className="info multiSelectWrapper">
                        <label htmlFor="value-2-tax">
                            {getTranslatedText('appliedTo', store)}
                        </label>
                        <Multiselect
                            options={providerForm.fees.fees}
                            selectedValues={selectedValues()}
                            onSelect={onSelectItem}
                            onRemove={onRemoveItem}
                            displayValue="name"
                        />
                    </div>
                </div>
                {showError && <span style={{ color: 'red' }}>
                    {getTranslatedText('addAllFields', store)}
                </span>}

                <div className="modal-footer">
                    <div className="flex-start">
                        <button
                            type="button"
                            className="clear"
                            onClick={hideModal}
                            data-dismiss="modal">
                            {getTranslatedText('cancel', store)}
                        </button>
                        <button onClick={addtax} className="default-btn">
                            {getTranslatedText('save', store)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TaxesModal;
