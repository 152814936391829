import React, { Component } from 'react';

const DefaultFilter = ({ filter, providerFilters, setProviderFilters }) => {

    const addFilter = (item) => {
        item.selected = !item.selected;
        if (item.selected) {
            setProviderFilters({
                ...providerFilters,
                filters: providerFilters.filters.length > 0 ? providerFilters.filters.concat(item) : [item],

            });
        } else {
            setProviderFilters({
                ...providerFilters,
                filters: providerFilters.filters.length > 0 ? providerFilters.filters.filter(item => item.id !== filter.id) : [],

            });
        }

    };
    return <div onClick={() => addFilter(filter)} className={'filter-import-container '}>
        <div className={`filter-import  ${filter.selected ? 'active' : null}`}>{filter.name}</div>
    </div>;

};
export default DefaultFilter;
