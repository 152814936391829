import React, { Component } from 'react';

const DefaultModifier = ({ modifier, providerModifiers, setProviderModifiers, existingModifiers }) => {
    const addModifier = () => {
        if (!(modifier.selected === null && existingModifiers.find(x => x.name === modifier.name && x.title === modifier.title))) {
            modifier.selected = !modifier.selected;
            if (modifier.selected) {
                setProviderModifiers({
                    ...providerModifiers,
                    modifiers: providerModifiers.modifiers.length > 0 ? providerModifiers.modifiers.concat(modifier) : [modifier],

                });
            } else {
                setProviderModifiers({
                    ...providerModifiers,
                    modifiers: providerModifiers.modifiers.length > 0 ? providerModifiers.modifiers.filter(item => item.id !== modifier.id) : [],

                });
            }
        }
    };

    return (
        <div className={'filter-import-container'} onClick={() => addModifier(modifier)}>
            <div className={`filter-import ${modifier.selected || modifier.selected === null && existingModifiers.find(x => x.name === modifier.name && x.title === modifier.title) ? 'active' : ''}`}>{modifier.name}</div>
        </div>
    );
};

export default DefaultModifier;
