import React from 'react';
import Config from '../Config';

const HCNImages = {
    getFullImageURL: (image_object, size = Config.IMAGE_SIZES.MAIN) => {
        let image_url = image_object && size in image_object ? image_object[size] : '';

        return `${Config.getS3BucketPrefix()}/${image_url}`;
    },
    updateImageObject: (image_object, size, filename) => {
        if (image_object && image_object.length > 0) {
            image_object[0][size] = filename;
        } else {
            image_object = [{[size]: filename}];
        }

        return image_object;
    },
};

export default HCNImages;
