import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../authorization/auth';
import './main-menu-btn.css';
import Config from '../../Config';
import { store } from '../../redux/store';
import { getTranslatedText } from '../../helpers/checkTranslations';

const MainMenuBtn = () => {
    const history = useHistory();
    const addRootCategory = () => {
        let header = document.querySelector('header');
        header.classList.toggle('toggled');
    };
    const logout = () => {
        auth.logout(() => {
            history.push('/');
        });
    };
    return (
        <>
            <button
                type="button"
                onClick={addRootCategory}
                className="rootCategory-toggle"
                aria-controls="primary-rootCategory"
                aria-expanded="false"
                title="rootCategory">
                <span className="rootCategory-icon">
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                </span>
            </button>
            <nav id="navigation" className="main-nav">
                <div id="primary-rootCategory" className="rootCategory-container">
                    <div className="rootCategory flex-column-center">
                        <ul className="flex-column-center">
                            <li onClick={logout} className="margin-bottom link">
                                {getTranslatedText('logOut', store)}
                            </li>
                            <li className="rootCategory-footer border-top padding-top">
                                V {Config.getVersion()}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default MainMenuBtn;
