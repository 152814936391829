import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ModifierModel from '../../data/modifier-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import InfoModal from '../info-modal';

const Modifier = ({ modifier, modifiers, setModifiers }) => {
    const [show, setShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });
    useEffect(() => {
        if (infoMessage.confirmation) {
            FilterModel.providerId ? ModifierModel.deleteProviderModifierById(modifier.id).then(res => {

                setModifiers(modifiers.filter(item => item.id !== modifier.id));
            }) :
                ModifierModel.deleteModifier(modifier.id).then(res => {
                    setModifiers(modifiers.filter(item => item.id !== modifier.id));
                });
        }
        return () => {
            setInfoMessage({});
        };
    }, [infoMessage.confirmation]);

    const hideModal = () => {
        setShow(false);
    };
    const deleteModifier = () => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteModifierWarning', store),
        });
    };
    return (
        <div className="row flex-between add-row-action" >
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show} />
            <div>
                <Link to={`/add-modifier/${modifier.id}/${modifier.name}`}>
                    <h2>{modifier.name}</h2>
                </Link>
                {
                    modifier.rawItems
                        ? <p className="sub-title">{modifier.rawItems.map(item => item.name).join(', ')}</p>
                        : <></>
                }
            </div>
            <div className="flex-start">
                <Link to={`/add-modifier/${modifier.id}/${modifier.name}/double`}>
                    <button className="copy">
                        <img src="../img/svg/duplicate.svg" alt="double item" />
                    </button>
                </Link>
                <button onClick={deleteModifier} className="remove">
                    <img src="../img/svg/trash.svg" alt="double item" />
                </button>
            </div>
        </div>
    );
};

export default Modifier;
