import Config from '../Config';

const HCNLoader = {
    show: dispatch => {
        dispatch({
            type: Config.REDUCER_TYPES.SET_LOADING,
            payload: true,
        });
    },
    hide: dispatch => {
        dispatch({
            type: Config.REDUCER_TYPES.SET_LOADING,
            payload: false,
        });
    },
};

export default HCNLoader;
