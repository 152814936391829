const HCNTimeSettings = {
    isDayIncluded: (day, days) => {
        return (day & days) > 0;
    },
    getTimeFromSum: sum => {
        let h = Math.floor(sum / 60);
        let m = Math.round((sum / 60 - h) * 60, 0);
        h = h === 24 ? 0 : h;

        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
    },
    getSumFromTime: time => {
        let timeObj = time.split(':');
        let h = parseInt(timeObj[0]);
        let m = parseInt(timeObj[1]);

        return h * 60 + m;
    },
};

export default HCNTimeSettings;
