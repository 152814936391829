import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './drop-zone.css';
import prettyBytes from 'pretty-bytes';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
const { forwardRef, useImperativeHandle } = React;

const DropZone = forwardRef((props, ref) => {
    const [files, setFiles] = useState([]);

    const accepted_mime_types = props.accepted_mime_types || 'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,image/gif,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
    const text = props.text || getTranslatedText('dragDrop', store);
    const multiple = props.multiple;

    const onDrop = useCallback(acceptedFiles => {
        setFiles(prev => [...prev, ...acceptedFiles]);
    }, []);
    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: accepted_mime_types,
        multiple: multiple,
        onDrop: onDrop,
    });

    useImperativeHandle(ref, () => ({
        clearData() {
            clearUploadedFile();
        },
    }));

    const clearUploadedFile = (path) => {
        path
            ? setFiles(files.filter(item => item.path !== path))
            : setFiles([]);
    };

    const acceptedFileItems = files.map(file => {
        return (
            <span key={file.path}>
                {file.path} - {prettyBytes(file.size)} (<span onClick={clearUploadedFile.bind(this, file.path)} className="delete-upload-file">{getTranslatedText('delete', store)}</span>)<br />
            </span>
        );
    });

    const onFilesUpload = () => {
        props.onFilesUpload(files);
    };

    return (
        <div className="dropzone-container">
            <div className="dropzone-upload-area">
                <div>
                    <h5>
                        {getTranslatedText('addPhotoDesc', store)}
                        <strong>50ΜΒ</strong>
                    </h5>
                    <section className="file-drop-container">
                        {files.length === 0 &&
                            <div {...getRootProps({ className: 'file-drop' })}>
                                <input {...getInputProps()} />
                                <h5>{text}</h5>
                            </div>
                        }
                        {files.length > 0 &&
                            <aside className="mt-4">
                                {acceptedFileItems}
                            </aside>
                        }
                    </section>
                </div>
            </div>
            {files.length > 0 &&
                <div className="dropzone-files-area">
                    <button
                        disabled={files.length === 0}
                        type="button"
                        color="success"
                        onClick={onFilesUpload.bind(this)}
                        className="float-right default-btn"
                    >
                        {getTranslatedText('upload', store)}
                    </button>
                </div>
            }
        </div>
    );
});

export default DropZone;
