import React, { Component } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import FeeModal from './feeContentModal';
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};
const Fee = ({ fee, id, providerForm, setProviderForm }) => {
    const [show, setShow] = useState(false);
    const [updateFee, setUpdateFee] = useState(false);
    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        if (updateFee) {
            setUpdateFee(false);
        }
        setShow(false);
    };

    const deleteFee = () => {
        setProviderForm({
            ...providerForm,
            fees: {
                fees: providerForm.fees.fees.filter(el => el !== fee),
                taxes: providerForm.fees.taxes,
                extraTaxes: providerForm.fees.extraTaxes,
            },
        });
    };
    const editFee = () => {
        setUpdateFee(true);
        showModal();
    };
    return (
        <div className="fees-row-body flex-between add-row-action">
            <ReactModal
                isOpen={show}
                ariaHideApp={false}
                style={customStyles}
            >
                <FeeModal
                    setUpdateFee={setUpdateFee}
                    updateFee={updateFee} id={id}
                    fee={fee}
                    setProviderForm={setProviderForm}
                    providerForm={providerForm}
                    hideModal={hideModal} />

            </ReactModal>
            <div className="fees-row-content">
                <span onClick={editFee}
                    className="col-3 fees-identifier cursor"
                    data-fee="identifier">
                    {fee.name}
                </span>
                <span
                    className="col-3 fees-value-1 text-center"
                    data-fee="value-1">
                    {fee.type === 0 ? getTranslatedText('currency', store) : ''}{fee.value}{fee.type === 0 ? '' : '%'}
                </span>
                <span
                    className="col-3 fees-value-2 text-center"
                    data-fee="value-2">
                    {fee.type === 0 ? getTranslatedText('add', store) : getTranslatedText('multiply', store)}
                </span>
            </div>
            <div className="fees-row-options">
                <button type="button" onClick={showModal} className="copy">
                    <img src=".././img/svg/duplicate.svg" alt="double item" />
                </button>
                <button type="button" className="remove"
                    onClick={deleteFee}
                >
                    <img src=".././img/svg/trash.svg" alt="trash" />
                </button>
            </div>
        </div>
    );
};

export default Fee;
