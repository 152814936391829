import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import FilterModel from "../../../data/filter-model";
import { getTranslatedText } from "../../../helpers/checkTranslations";
import { store } from "../../../redux/store";

const SidebarProvider = () => {
  const history = useHistory();
  const goTo = () => {
    FilterModel.providerId = undefined;
    FilterModel.provider = undefined;
    store.getState().Reducer.isAdmin
      ? history.push("/")
      : history.push("/providers");
  };
  return (
    <>
      <div>
        <p>{getTranslatedText("provider", store)}</p>
        <h3>{FilterModel.provider.name}</h3>
      </div>
      <button onClick={goTo} className="btn add-btn">
        <span>
          <img src={"/img/svg/arrow_right.svg"} alt="next" />
        </span>
      </button>
    </>
  );
};

export default SidebarProvider;
