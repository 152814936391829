import React, { Component } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../App';
import FilterModel from '../../data/filter-model';
import RootCategoryModel from '../../data/rootCategory-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import FeatureSection from './feature-section';
import SectionButton from './feature-section/section-button';
import RootCategoryCategories from './rootCategory-categories';
import './rootCategory.css';
import RootCategoryItems from './rootCategory-items';
import ItemModel from '../../data/item-model';
import { useDispatch } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import Config from '../../Config';

const AddRootCategory = (props) => {
    const context = useContext(Context);

    const [rootCategory, setRootCategory] = useState({ name: '', description: '', providerId: FilterModel.providerId, available: true, type: Config.getDineIn() ? 0 : 2 });
    const [rootCategorySections, setRootCategorySections] = useState([]);
    const [rootCategorySection, setRootCategorySection] = useState(undefined);
    const [rootCategoryCategoryItems, setRootCategoryCategoryItems] = useState([]);
    const [edited, setEdited] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryItems, setCategoryItems] = useState([]);
    const [active, setActive] = useState(true);
    const [drag, setDrag] = useState(false);
    const [draggedList, updateDraggedList] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const param = props.component
        ? props.component.location.pathname.split('/add-rootCategory/')[1]
        : undefined;

    const uniqBy = (arr, predicate) => {
        const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

        return [...arr.reduce((map, item) => {
            const key = item === null || item === undefined ?
                item : cb(item);

            map.has(key) || map.set(key, item);

            return map;
        }, new Map()).values()];
    };

    useEffect(() => {
        return () => {
            setRootCategoryCategoryItems([]);
            setRootCategorySection({});
            setRootCategorySections([]);
            setSelectedCategory(null);
            setCategoryItems([]);
        };
    }, []);


    console.log({ xa: Config.getDineIn() })
    useEffect(() => {
        setEdited(false);
        if (param) {
            setEdited(true);
            let params = param.split('/');
            RootCategoryModel.getRootCategoryById(params[0]).then(res => {
                setRootCategory({
                    id: res.id, name: res.name, description: res.description, providerId: res.providerId, available: res.available, type: res.type
                });
                RootCategoryModel.getRootCategorySections(res.id).then(res => {
                    setRootCategorySections(res);
                });
                setRootCategorySection(undefined);
            });
        } else {
            setRootCategory({ name: '', description: '', providerId: FilterModel.providerId, available: true, type: Config.getDineIn() ? 0 : 2 });
            setRootCategorySections([]);
            setRootCategorySection(undefined);
        }
    }, [param]);

    useEffect(() => {
        setSelectedCategory(null);
        setCategoryItems([]);

        if (rootCategory && rootCategory.id) {
            HCNLoader.show(dispatch);
            RootCategoryModel
                .getRootCategoryCategories(rootCategory.id)
                .then(response => {
                    setRootCategoryCategoryItems(response);
                    HCNLoader.hide(dispatch);
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        } else {
            setRootCategoryCategoryItems([]);
        }

    }, [rootCategory.id]);

    useEffect(() => {
        updateDraggedList(rootCategorySections);
    }, [rootCategorySections]);

    useEffect(() => {
        if (selectedCategory && selectedCategory.id) {
            // HCNLoader.show(dispatch);
            ItemModel
                .getRootCategoryCategoryItems(selectedCategory.id)
                .then(response => {
                    setCategoryItems(response);
                    HCNLoader.hide(dispatch);
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        } else {
            setCategoryItems([]);
        }
        return () => {
            setCategoryItems([]);
        };

    }, [selectedCategory]);

    const enableInput = () => {
        setEdited(!edited);
    };

    const getResponse = (res) => {
        setRootCategory({
            ...res,
        });
        // HCNLoader.show(dispatch);
        history.push(`/add-rootCategory/${res.id}/${res.name}`);
        RootCategoryModel.getAllRootCategorys(FilterModel.providerId).then(
            res => {
                HCNLoader.hide(dispatch);
                context.setRootCategories(res.map(item => {
                    return {
                        ...item,
                        selected: false,
                    };
                }));

            },
        );
    };

    const createNewRootCategory = () => {
        HCNLoader.show(dispatch);
        RootCategoryModel
            .createRootCategory(rootCategory)
            .then(res => {
                getResponse(res);
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err?.message);
                HCNLoader.hide(dispatch);
            });
    };

    const updateRootCategoryRootCategory = () => {

        let updatedRootCategory = {
            ...rootCategory,
            name: rootCategory.name,
            description: rootCategory.description,
            available: rootCategory.available,
        };

        HCNLoader.show(dispatch);
        RootCategoryModel
            .updateRootCategory(rootCategory.id, updatedRootCategory)
            .then(res => {
                getResponse(res);
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
        !drag && selectedCategory && RootCategoryModel.updateRootCategoryCategory(selectedCategory)
            .then(res => {
                setRootCategoryCategoryItems(rootCategoryCategoryItems.map(item =>
                    item.id === selectedCategory.id
                        ? { ...item, name: selectedCategory.name }
                        : { ...item }));
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });

        drag && selectedCategory && RootCategoryModel.putOrderCategoriesInRootCategory(rootCategory.id, rootCategoryCategoryItems.map(it => it.id))
            .then(() => {
                toast(getTranslatedText('rootCategoryUpdatedSuccess', store));
                //    setTimeout(() => {
                //        window.location.reload()
                //     }, 1000);
            })
            .catch(err => toast(err.message));
    };

    const createRootCategory = (event) => {
        event.preventDefault();
        param ? updateRootCategoryRootCategory() : createNewRootCategory();
    };

    const addCategory = () => {
        history.push(`/add-category/${rootCategory.id}/${rootCategory.name}`);
    };

    const editCategory = () => {
        dispatch({
            type: Config.REDUCER_TYPES.SET_CATEGORY,
            payload: selectedCategory,
        });
        history.push(`/edit-category/${rootCategory.id}/${rootCategory.name}`);
    };

    const addFetureSection = () => {
        setRootCategorySection({ name: '', description: '', rootCategoryId: rootCategory.id });
    };

    const changeRootCategoryAvailability = (e) => {
        setRootCategory({ ...rootCategory, available: e.target.checked });
    };

    const handleOnDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(draggedList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateDraggedList(items);
        setRootCategorySections(items);

    };

    console.log({ rootCategory })

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <form>
                        <header id="header" className="site-header">
                            <div className="flex-between">
                                <div>
                                    <h1 className="main-title">
                                        <div className="info info-title" data-info="item_name">
                                            <input
                                                id="item_name"
                                                name="item_name"
                                                placeholder='rootCategory name'
                                                disabled={edited}
                                                value={rootCategory.name}
                                                onChange={event =>
                                                    setRootCategory({
                                                        ...rootCategory,
                                                        name: event.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                    </h1>
                                    <a onClick={enableInput} className="edit-title">
                                        edit
                                    </a>
                                </div>
                                {!Config.getDineIn() && <div>
                                    <span>Type</span>
                                    <br />
                                    <select value={rootCategory.type} onChange={value => setRootCategory({ ...rootCategory, type: value })}>
                                        <option value={1}>Services</option>
                                        <option value={2}>Amenities</option>
                                    </select>
                                </div>}
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <label className="switch margin-right">
                                            <input
                                                type="checkbox"
                                                checked={rootCategory.available}
                                                onChange={changeRootCategoryAvailability}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <button onClick={createRootCategory} type="submit" className="btn default-btn">{getTranslatedText('save', store)}</button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        {rootCategory && rootCategory.id && <main>
                            <section className="tabbed">
                                <div className="tabbed-options flex-between ai-start">
                                    <div className="tabbs grid-flex">
                                        <button
                                            type="button"
                                            className={'tab category-btn-main' + (!rootCategorySection ? ' active' : '')}
                                            data-tab="tab-1"
                                            onClick={() => {
                                                setRootCategorySection(undefined);
                                            }}>
                                            Categories
                                        </button>
                                        <span className="vr-line-37"></span>

                                        {drag ?
                                            // <DragDropContext onDragEnd={handleOnDragEnd}>
                                            //     <Droppable droppableId='rootCategorySections'>
                                            //         {provided =>
                                            //             <div {...provided.droppableProps} ref={provided.innerRef} >
                                            //                 {rootCategorySections && rootCategorySections.map((fsect, index) =>
                                            //                     <Draggable key={index} index={index} draggableId={`${fsect.name}${fsect.id}`}>
                                            //                     {provided =>
                                            //                         <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="grid-flex category-btn-items">
                                            //                             <SectionButton
                                            //                                 fsect={fsect}
                                            //                                 rootCategorySection={rootCategorySection}
                                            //                                 rootCategorySections={rootCategorySections}
                                            //                                 setRootCategorySections={setRootCategorySections}
                                            //                                 setRootCategorySection={setRootCategorySection}
                                            //                                 key={index + '_btn'}
                                            //                                 setSelectedCategory={setSelectedCategory}
                                            //                                 drag={drag}
                                            //                             />
                                            //                         </div>
                                            //                     }
                                            //                     </Draggable>,
                                            //                 )}
                                            //                 {provided.placeholder}
                                            //             </div>
                                            //         }
                                            //     </Droppable>
                                            // </DragDropContext>
                                            <div className=''></div>
                                            : rootCategorySections && rootCategorySections.map((fsect, index) =>
                                                <SectionButton
                                                    fsect={fsect}
                                                    rootCategorySection={rootCategorySection}
                                                    rootCategorySections={rootCategorySections}
                                                    setRootCategorySections={setRootCategorySections}
                                                    setRootCategorySection={setRootCategorySection}
                                                    key={index + '_btn'}
                                                    setSelectedCategory={setSelectedCategory}
                                                />,
                                            )
                                        }
                                    </div>
                                    <div className="grid-flex">
                                        {/* when someone click this button we need to add a class on the previous div.category-btn-items and make the whole buttons draggable
                                            also need to add a class on the next div.tab-carousel and make again the whole carousel items draggable */}
                                        <button
                                            type="button"
                                            className="grid-display-btn"
                                            style={drag
                                                ? { backgroundColor: '#1793c2' }
                                                : { backgroundColor: 'transparent', border: '1px solid #fff' }}
                                            onClick={() => {
                                                setDrag(!drag);
                                                setSelectedCategory({});
                                            }}
                                        >
                                            <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="2.45455" cy="2.45455" r="2.45455" fill="white" />
                                                <circle cx="2.45455" cy="9.0002" r="2.45455" fill="white" />
                                                <circle cx="2.45455" cy="15.5454" r="2.45455" fill="white" />
                                                <circle cx="9.81807" cy="2.45455" r="2.45455" fill="white" />
                                                <circle cx="9.81807" cy="9.0002" r="2.45455" fill="white" />
                                                <circle cx="9.81807" cy="15.5454" r="2.45455" fill="white" />
                                            </svg>
                                        </button>
                                        <button onClick={addFetureSection} type="button" className="default-btn cst-size-btn">{getTranslatedText('addFeaturedSection', store)}</button>
                                    </div>
                                </div>
                                {!rootCategorySection &&
                                    <div className="tab-carousel-wrapper flex-start">
                                        <div className="tab-carousel-icon add-new">
                                            <button disabled={!rootCategory.id} onClick={addCategory} type="button" className={'add-icon link'}>+</button>
                                            <p className="add-text">{getTranslatedText('newCategory', store)}</p>
                                        </div>
                                        <RootCategoryCategories
                                            rootCategoryCategoryItems={rootCategoryCategoryItems}
                                            setRootCategoryCategoryItems={setRootCategoryCategoryItems}
                                            setSelectedCategory={setSelectedCategory}
                                            selectedCategory={selectedCategory}
                                            rootCategory={rootCategory}
                                            drag={drag}
                                        />
                                    </div>
                                }
                                {selectedCategory && !drag && <div style={{ display: 'flex', justifyContent: 'space-between' }} className='margin-top-20'>
                                    <div>
                                        <span className={active ? 'active-btn cst-size-btn padding-vertical-20 pointer' : 'inactive-btn cst-size-btn padding-vertical-20 pointer'}
                                            onClick={() => setActive(true)}
                                        >
                                            Category Items
                                        </span>

                                        <span className={!active ? 'active-btn cst-size-btn padding-vertical-20 pointer' : 'inactive-btn cst-size-btn padding-vertical-20 pointer'}
                                            onClick={() => setActive(false)}
                                        >
                                            Suggestion Items
                                        </span>
                                    </div>
                                    <span
                                        className={'inactive-btn padding-vertical-20 pointer'}
                                        onClick={editCategory}
                                    >
                                        Edit
                                    </span>
                                </div>}
                                {rootCategorySection && !drag &&
                                    <FeatureSection
                                        rootCategorySections={rootCategorySections}
                                        setRootCategorySection={setRootCategorySection}
                                        setRootCategorySections={setRootCategorySections}
                                        rootCategorySection={rootCategorySection}
                                        drag={drag}
                                    />
                                }
                                {!rootCategorySection && selectedCategory && !drag &&
                                    <RootCategoryItems
                                        active={active}
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={setSelectedCategory}
                                        categoryItems={categoryItems}
                                        setCategoryItems={setCategoryItems}
                                        drag={drag}
                                    />
                                }
                            </section>

                        </main>}
                    </form>
                </div>

            </div>
        </div >
    );
};
export default AddRootCategory;
