import React, { useEffect, useState } from 'react';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import { Link, useHistory } from 'react-router-dom'
import PromotionsModel from '../../data/promotions-model';
import { toast } from 'react-toastify';
import InfoModal from '../info-modal';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const Coupons = () => {
    const [coupons, setCoupons] = useState(null);
    const [activeCoupons, setActiveCoupons] = useState(null);
    const [upcomingCoupons, setUpcomingCoupons] = useState(null);
    const [expiredCoupons, setExpiredCoupons] = useState(null);
    const [couponID, setCouponID] = useState(null);
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(0);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });

    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            PromotionsModel.remote_getAll()
                .then(res => setCoupons(res))
                .then(res => res && coupons ? console.log('xaxa') : console.log('xoxo'))
                .catch(err => toast(err.message))
        }, 500);
    }, [])

    useEffect(() => {
        if (coupons) {
            setUpcomingCoupons(
                coupons.filter(coupon => coupon.start && new Date(coupon.start).getTime() > new Date().getTime()),
            )
        }
    }, [coupons])


    useEffect(() => {
        if (upcomingCoupons) {
            setActiveCoupons(
                coupons.filter(coupon =>
                    !coupon.end
                    || coupon.start && new Date(coupon.start).getTime() < new Date().getTime()
                    && new Date(coupon.end).getTime() > new Date().getTime()
                    || coupon.uses < coupon.times)
                    .filter(coupon => upcomingCoupons.find(up => up.code === coupon.code)?.code !== coupon.code),
            )
        }
    }, [upcomingCoupons])

    useEffect(() => {
        if (activeCoupons) {
            setExpiredCoupons(
                coupons
                    .filter(coupon => activeCoupons.find(act => act.code === coupon.code)?.code !== coupon.code)
                    .filter(coupon => upcomingCoupons.find(up => up.code === coupon.code)?.code !== coupon.code),
            )
        }
    }, [activeCoupons])

    useEffect(() => {
        if (infoMessage.confirmation) {
            coupons && PromotionsModel.remote_deleteOne(couponID)
                .then(() => setCoupons(coupons.filter(x => x.id !== couponID)))
                .catch(err => toast(err.message))
        }
        return () => {
            setInfoMessage({});
        };
    }, [infoMessage.confirmation]);

    const deleteCoupon = (id) => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteCouponWarning', store),
        });
        setCouponID(id)
    };

    const hideModal = () => {
        setShow(false);
    };

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <InfoModal
                    setInfoMessage={setInfoMessage}
                    infoMessage={infoMessage}
                    hideModal={hideModal}
                    show={show}
                />
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className='site-header'>
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText('coupons', store)}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button
                                            onClick={() => history.push('/add-coupon')}
                                            type='submit'
                                            className="btn default-btn">
                                            {getTranslatedText('addCoupon', store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: 50, marginBottom: -20 }}>
                                <span className={
                                    active === 0
                                        ? 'active-btn cst-size-btn pointer'
                                        : 'inactive-btn cst-size-btn pointer'
                                }
                                    style={{ padding: '12px 24px' }}
                                    onClick={() => setActive(0)}
                                >
                                    {getTranslatedText('active', store)}
                                </span>

                                <span className={
                                    active === 1
                                        ? 'active-btn cst-size-btn pointer'
                                        : 'inactive-btn cst-size-btn pointer'
                                }
                                    style={{ marginLeft: 12, padding: '12px 24px' }}
                                    onClick={() => setActive(1)}
                                >
                                    {getTranslatedText('upcoming', store)}
                                </span>

                                <span className={
                                    active === 2
                                        ? 'active-btn cst-size-btn pointer'
                                        : 'inactive-btn cst-size-btn pointer'
                                }
                                    style={{ marginLeft: 12, padding: '12px 24px' }}
                                    onClick={() => setActive(2)}
                                >
                                    {getTranslatedText('expired', store)}
                                </span>
                            </div>
                        </header>
                        <main className="spacing">
                            <div className='flex-between border-bottom padding-top'>
                                <span style={{ width: '20%', fontWeight: 700 }}>
                                    {getTranslatedText('couponName', store)}
                                </span>
                                <span style={{ width: '20%', fontWeight: 700 }}>
                                    {getTranslatedText('couponCode', store)}
                                </span>
                                <span style={{ width: '20%', fontWeight: 700 }}>
                                    {getTranslatedText('couponStartDate', store)}
                                </span>
                                <span style={{ width: '15%', fontWeight: 700 }}>
                                    {getTranslatedText('couponEndDate', store)}
                                </span>
                                <span style={{ width: '15%', fontWeight: 700 }}>
                                    {getTranslatedText('couponUses', store)}
                                </span>
                                <span style={{ width: '10%' }}></span>
                            </div>
                            {activeCoupons && active === 0 &&
                                activeCoupons.length > 0 &&
                                activeCoupons
                                    .sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())
                                    .map(coupon =>
                                        <div className='flex-between border-top padding-top'>
                                            <Link to={{
                                                pathname: '/add-coupon',
                                                state: { title: coupon.title, couponClone: coupon, clone: false },
                                            }} style={{ width: '20%' }} className='pointer'>{coupon.title}</Link>
                                            <span style={{ width: '20%' }}>{coupon.code}</span>
                                            <span style={{ width: '20%' }}>{coupon.start ? coupon.start.split('T')?.[0] : getTranslatedText('startsImmediately', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.end ? coupon.end.split('T')?.[0] : getTranslatedText('neverEnds', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.times > 0 ? `${coupon.uses} of ${coupon.times}` : getTranslatedText('unlimited', store)}</span>
                                            <div style={{ width: '10%' }} className='flex-start pointer'>
                                                <Link to={{
                                                    pathname: '/add-coupon',
                                                    state: { title: coupon.title, times: coupon.times, couponClone: coupon, clone: true },
                                                }}>
                                                    <button className='copy'>
                                                        <img src="../img/svg/duplicate.svg" alt='double item' />
                                                    </button>
                                                </Link>
                                                <button onClick={() => deleteCoupon(coupon.id)} className='remove'>
                                                    <img src='../img/svg/trash.svg' alt='double item' />
                                                </button>
                                            </div>
                                        </div>,
                                    )}
                            {upcomingCoupons && active === 1 &&
                                upcomingCoupons.length > 0 &&
                                upcomingCoupons.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())
                                    .map(coupon =>
                                        <div className='flex-between border-top padding-top'>
                                            <Link to={{
                                                pathname: '/add-coupon',
                                                state: { title: coupon.title, couponClone: coupon, clone: false },
                                            }} style={{ width: '20%' }} className='pointer'>{coupon.title}</Link>
                                            <span style={{ width: '20%' }}>{coupon.code}</span>
                                            <span style={{ width: '20%' }}>{coupon.start ? coupon.start.split('T')?.[0] : getTranslatedText('startsImmediately', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.end ? coupon.end.split('T')?.[0] : getTranslatedText('neverEnds', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.times > 0 ? `${coupon.uses} of ${coupon.times}` : getTranslatedText('unlimited', store)}</span>
                                            <div style={{ width: '10%' }} className='flex-start pointer'>
                                                <Link to={{
                                                    pathname: '/add-coupon',
                                                    state: { title: coupon.title, times: coupon.times, couponClone: coupon, clone: true },
                                                }}>
                                                    <button className='copy'>
                                                        <img src="../img/svg/duplicate.svg" alt='double item' />
                                                    </button>
                                                </Link>
                                                <button onClick={() => deleteCoupon(coupon.id)} className='remove'>
                                                    <img src='../img/svg/trash.svg' alt='double item' />
                                                </button>
                                            </div>
                                        </div>,
                                    )}
                            {expiredCoupons && active === 2 &&
                                expiredCoupons.length > 0 &&
                                expiredCoupons.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())
                                    .map(coupon =>
                                        <div className='flex-between border-top padding-top'>
                                            <Link to={{
                                                pathname: '/add-coupon',
                                                state: { title: coupon.title, couponClone: coupon, clone: false },
                                            }} style={{ width: '20%' }} className='pointer'>{coupon.title}</Link>
                                            <span style={{ width: '20%' }}>{coupon.code}</span>
                                            <span style={{ width: '20%' }}>{coupon.start ? coupon.start.split('T')?.[0] : getTranslatedText('startsImmediately', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.end ? coupon.end.split('T')?.[0] : getTranslatedText('neverEnds', store)}</span>
                                            <span style={{ width: '15%' }}>{coupon.times > 0 ? `${coupon.uses} of ${coupon.times}` : getTranslatedText('unlimited', store)}</span>
                                            <div style={{ width: '10%' }} className='flex-start pointer'>
                                                <Link to={{
                                                    pathname: '/add-coupon',
                                                    state: { title: coupon.title, times: coupon.times, couponClone: coupon, clone: true },
                                                }}>
                                                    <button className='copy'>
                                                        <img src="../img/svg/duplicate.svg" alt='double item' />
                                                    </button>
                                                </Link>
                                                <button onClick={() => deleteCoupon(coupon.id)} className='remove'>
                                                    <img src='../img/svg/trash.svg' alt='double item' />
                                                </button>
                                            </div>
                                        </div>,
                                    )}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Coupons