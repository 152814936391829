import React from 'react';
import HCNImages from '../../helpers/hcn-images';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const ChooseProviders = ({ show, coords, rows, setRows, providers, closeModal }) => {

    const addToRow = (provider) => {
        rows[coords.out][coords.in] = provider
        closeModal();
    }

    return <div id="select-item-modal" className="select-item-modal modal-container" data-attribute="modal-window">
        <div className="modal-wrapper modal-lg">
            <button type="button" onClick={closeModal} className="close-modal" data-dismiss="modal">x</button>
            <form action="">
                <div className="modal-head">
                    <div className="tabbed-options flex-between border-bottom-gray">
                        <h3 className="modal-title reset">
                            {getTranslatedText('chooseProvider', store)}
                        </h3>
                    </div>
                </div>
                <div className="modal-body">
                    {providers.map(item =>
                        <div className='grid-flex margin-top-30 pointer' onClick={() => addToRow(item)}>
                            <img src={HCNImages.getFullImageURL(item.images?.[0])} width="100" />
                            <span className='margin-left-10 margin-top-20'>{item.name}</span>
                        </div>,
                    )}
                </div>
            </form>
        </div>
    </div>

}

export default ChooseProviders