import axios from 'axios';
import Config from '../Config';
import LocalData from '../helpers/local-data';
// import Auth from './auth';

class Services {

    // static checkForUnauthorisedAccess(error) {
    //     if (error && error.response && error.response.status === 401) {
    //         Auth.resetAuth();
    //         window.location.replace('/#' + Config.URLs.login);
    //     }
    // }

    static checkForAccessTokenOrGenerate() {
        return new Promise((resolve, reject) => {
            let access_token = LocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN);
            if (!access_token) {
                axios.post(Config.getAuthBasePath() + '/oauth/token', {
                    'grant_type': 'client_credentials',
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${btoa(
                            Config.getAccessTokenUsername() +
                            ':' +
                            Config.getAccessTokenPassword(),
                        )}`,
                    },
                })
                    .then(function(response) {
                        if (
                            [200, 201].includes(response.status)
                        ) {
                            LocalData.set(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN, response.data.access_token);
                            resolve(response.data.access_token);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }

            resolve(access_token);
        });
    }

    static wrapError(error) {
        if (error && error.response && error.response.status) {
            return error;
        } else {
            return {
                response: {
                    code: 500,
                    message: error && error.message ? error.message : 'An error has occurred, please try again later.',
                },
            };
        }
    }

    static getDefaultHeaders(useBasicAuth = false, contentType = 'application/json') {
        // let token = LocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN);
        let jwt_token = LocalData.get(Config.LOCAL_DATA_KEYS.JWT_ACCESS_TOKEN);

        // if (!token) {
        //     token = await Services.checkForAccessTokenOrGenerate();
        // }

        let headers = {
            'Content-Type': contentType,
            Authorization: !useBasicAuth
                ? 'Bearer ' + jwt_token
                : `Basic ${btoa(
                    Config.getAccessTokenUsername() +
                    ':' +
                    Config.getAccessTokenPassword(),
                )}`,
        };

        return headers;
    }

    static getApi(apiUrl) {
        return Services.checkForAccessTokenOrGenerate().then(() => {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl, {
                    headers: Services.getDefaultHeaders(),
                })
                    .then(function(response) {
                        if (
                            [200, 201].includes(response.status)
                        ) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        // Services.checkForUnauthorisedAccess(error);
                        reject(Services.wrapError(error).response.data);
                    });
            });
        });
    }
    static deleteApi(apiUrl) {
        return Services.checkForAccessTokenOrGenerate().then(() => {
            return new Promise((resolve, reject) => {
                axios.delete(apiUrl, {
                    headers: Services.getDefaultHeaders(),
                })
                    .then(function(response) {
                        if (
                            [200, 201, 204].includes(response.status)
                        ) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        // Services.checkForUnauthorisedAccess(error);
                        reject(Services.wrapError(error).response.data);
                    });
            });
        });
    }
    static postApi(apiUrl, criteria, useBasicAuth = false, contentType = 'application/json') {
        return new Promise((resolve, reject) => {
            axios.post(apiUrl, criteria, {
                headers: Services.getDefaultHeaders(useBasicAuth, contentType),
            })
                .then(function(response) {
                    if (
                        [200, 201, 204].includes(response.status)
                    ) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch(function(error) {
                    reject(Services.wrapError(error).response.data);
                });
        });
    }

    static putApi(apiUrl, criteria) {
        return Services.checkForAccessTokenOrGenerate().then(() => {
            return new Promise((resolve, reject) => {
                axios.put(apiUrl, criteria, {
                    headers: Services.getDefaultHeaders(),
                })
                    .then(function(response) {
                        if (
                            [200, 201, 204].includes(response.status)
                        ) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        // Services.checkForUnauthorisedAccess(error);
                        reject(Services.wrapError(error).response.data);
                    });
            });
        });
    }
    // static postApi(apiUrl, criteria, callback, errorCallback) {
    //     return axios.post(apiUrl, criteria, {
    //         headers: Services.getDefaultHeaders(),
    //     })
    //         .then(function(response) {
    //             if (response.data.error === false && [200, 201].includes(response.data.code)) {
    //                 callback(response.data.data);
    //             } else {
    //                 errorCallback(response.data.message);
    //             }
    //         })
    //         .catch(function(error) {
    //             Services.checkForUnauthorisedAccess(error);
    //             errorCallback(Services.wrapError(error).response.data.message);
    //         });
    // }
    //
    // static putApi(apiUrl, criteria, callback, errorCallback) {
    //     axios.put(apiUrl, criteria,{
    //         headers: Services.getDefaultHeaders(),
    //     })
    //         .then(function(response) {
    //             if (response.data.error === false && [200, 201].includes(response.data.code)) {
    //                 callback(response.data.data);
    //             } else {
    //                 errorCallback(response.data.message);
    //             }
    //         })
    //         .catch(function(error) {
    //             Services.checkForUnauthorisedAccess(error);
    //             errorCallback(Services.wrapError(error).response.data.message);
    //         });
    // }
    //
    // static deleteApi(apiUrl, callback, errorCallback) {
    //     axios.delete(apiUrl, {
    //         headers: Services.getDefaultHeaders(),
    //     })
    //         .then(function(response) {
    //             if (response.data.error === false && [200, 201].includes(response.data.code)) {
    //                 callback(response.data.data);
    //             } else {
    //                 errorCallback(response.data.message);
    //             }
    //         })
    //         .catch(function(error) {
    //             Services.checkForUnauthorisedAccess(error);
    //             errorCallback(Services.wrapError(error).response.data.message);
    //         });
    // }
}

export default Services;
