const HCNPrice = {
    format: (price, decimals = 2) => {
        return '$' + parseFloat(price).toFixed(decimals);
    },
    presentFormat: (present, decimals = 2) => {
        return parseFloat(present).toFixed(decimals) + '%';
    },
    calculateTotalPriceFromItems: (items) => {
        let price = 0;
        items.map((item) => {
            let itemPrice = parseFloat(item.price);
            item.modifiers.map((modifier) => {
                itemPrice += parseFloat(modifier.modifierOptionPrice);
            });
            itemPrice = itemPrice * item.quantity;
            price += itemPrice;
        });
        return HCNPrice.format(price);
    },
    calculateItemPrice: (item) => {
        let price = 0;
        let itemPrice = parseFloat(item.price);
        item.modifiers.map(modifier => {
            itemPrice += parseFloat(modifier.modifierOptionPrice);
        });
        price += itemPrice * item.quantity;
        return HCNPrice.format(price);
    },
    calculatePercentageFeePrices: (provider, items) => {
        let price = 0;
        items.map((item) => {
            let itemPrice = parseFloat(item.price);
            item.modifiers.map((modifier) => {
                itemPrice += parseFloat(modifier.modifierOptionPrice);
            });
            itemPrice = itemPrice * item.quantity;
            price += itemPrice;
        });
        provider && provider.fees && provider.fees.taxes.map(tax => {
            price = price * parseFloat(tax.percentage) / 100;
        });
        return HCNPrice.format(price);
    },
    calculateTotalCartPrice: (provider, items, tipValue) => {
        let price = 0;
        let feePrice = 0;
        let taxPrice = 0;
        let tip = 0;
        items.map((item) => {
            let itemPrice = parseFloat(item.price);
            item.modifiers.map((modifier) => {
                itemPrice += parseFloat(modifier.modifierOptionPrice);
            });
            itemPrice = itemPrice * item.quantity;
            price += itemPrice;
        });
        provider && provider.fees && provider.fees.taxes.map(tax => {
            taxPrice = price * parseFloat(tax.percentage) / 100;
        });
        // provider && provider.fees && provider.fees.extraTaxes.map(extraTax => {
        //     price = price + price * parseFloat(extraTax.percentage) / 100;
        // });
        provider && provider.fees && provider.fees.fees.map(fees => {
            feePrice += parseFloat(fees.value);
        });
        tip = price * tipValue;
        price += taxPrice + feePrice + tip;
        return HCNPrice.format(price);
    },
    calculateTip: (items, tipValue) => {
        let tip = 0;
        let price = 0;
        items.map((item) => {
            let itemPrice = parseFloat(item.price);
            item.modifiers.map((modifier) => {
                itemPrice += parseFloat(modifier.modifierOptionPrice);
            });
            itemPrice = itemPrice * item.quantity;
            price += itemPrice;
        });
        tip = price * tipValue;
        return HCNPrice.format(tip);
    },
};

export default HCNPrice;
