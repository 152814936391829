import React, { Component } from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FilterModel from "../../data/filter-model";
import ProviderModel from "../../data/provider-model";
import InfoModal from "../info-modal";
import { useDispatch } from "react-redux";
import HCNLoader from "../../helpers/hcn-loader";
import HCNImages from "../../helpers/hcn-images";
import { toast } from "react-toastify";
import { store } from "../../redux/store";
import { getTranslatedText } from "../../helpers/checkTranslations";

const Provider = ({ provider, setProviders, providers }) => {
  const [show, setShow] = useState(false);
  const [infoMessage, setInfoMessage] = useState({
    message: "",
    confirmation: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (infoMessage.confirmation) {
      HCNLoader.show(dispatch);
      ProviderModel.remote_deleteOneProvider(provider)
        .then(() => {
          setProviders(providers.filter((res) => res.id !== provider.id));
          HCNLoader.hide(dispatch);
        })
        .catch((err) => {
          toast(err.message);
          HCNLoader.hide(dispatch);
        });
    }
    return () => {
      setInfoMessage({});
    };
  }, [infoMessage.confirmation]);

  const hideModal = () => {
    setShow(false);
  };

  const history = useHistory();

  const removeProvider = () => {
    setShow(true);
    setInfoMessage({
      ...infoMessage,
      message: getTranslatedText("deleteProviderWarning", store),
    });
  };

  const addFilter = () => {
    FilterModel.providerId = provider.id;
    FilterModel.provider = provider;
    history.push("/welcome-provider");
  };

  return (
    <div className="row flex-between add-row-action">
      <InfoModal
        setInfoMessage={setInfoMessage}
        infoMessage={infoMessage}
        hideModal={hideModal}
        show={show}
      />

      <Link
        className="flex-between"
        to={"/welcome-provider"}
        onClick={() => addFilter()}>
        <img
          className="padding-right"
          src={HCNImages.getFullImageURL(
            provider.images && provider.images[0],
            provider.images?.[0]?.thumbnail
              ? "thumbnail"
              : provider.images?.[0]?.default
              ? "default"
              : "main"
          )}
          width="100"
        />
        {provider.name}
      </Link>

      <div className="flex-start">
        <button
          type="button"
          onClick={() => history.push(`/provider-info/${provider.id}`)}
          className="default-btn add-rootCategory">
          {getTranslatedText("configure", store)}
        </button>
        {store.getState().Reducer.isAdmin && (
          <Link to={`/provider-info/${provider.id}/double`}>
            <button className="copy">
              <img src="../img/svg/duplicate.svg" alt="double item" />
            </button>
          </Link>
        )}

        {store.getState().Reducer.isAdmin && (
          <button onClick={removeProvider} className="remove">
            <img src="../img/svg/trash.svg" alt="double item" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Provider;
