import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import { default as plus } from '../plus-sign.svg';
const Coupons = () => {

    return (
        <>
            <Link to="/coupons-info">
                <p data-component="provider">{getTranslatedText('coupons', store)}</p>
            </Link>
            <Link to={'/add-coupon'}>
                <button className="btn add-btn">
                    <span>
                        <img src={plus} alt="add" />
                    </span>
                </button>
            </Link>
        </>
    );
};
export default Coupons;
