const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmails = (input) => {

	const inputTrimmed = input.trim()
	const emails = (inputTrimmed.split(',')).filter(item => console.log(!!item) || !!(item.trim()))

	for (const email of emails) {
		const emailedTrimmed = email.trim()
		console.log(emails)
		const error = !re.exec(emailedTrimmed)

		if (error) {
			return false
		}
	}

	return true
	
}