import React, { Component, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RootCategoryModel from '../../../data/rootCategory-model';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import CategoryIcons from '../../category-icons/category-icons';
import { default as magnifier } from '../../magnifying-glass.svg';
import SideBar from '../../side-bar/side-bar';
import CategoryIcon from './category-icon';
import Multiselect from 'multiselect-react-dropdown';
import HCNTimeSettings from '../../../helpers/hcn-time-settings';
import { AdminUsersModel } from '../../../data/admin-users-model';
import Config from '../../../Config';

const AddCategory = (props) => {
    const categoryRedux = store.getState().Reducer.category;
    const [categories, setCategories] = useState(CategoryIcons?.getIconList().filter(o => o === categoryRedux?.images?.[0])?.map(x => {
        return {
            name: x,
            icon: CategoryIcons.getIconUrlByName(x),
            selected: true,
        };
    }));

    const [isDinein, set] = useState(Config.getDineIn())
    const [dispatchers, setDispatchers] = useState(categoryRedux?.meta?.dispatchers || [])
    const [type, setType] = useState(categoryRedux?.type);
    const [users, setUsers] = useState([]);

    const [iconCategories, setIconCategories] = useState({ ...CategoryIcons.icons });
    const history = useHistory();
    const param = props.component
        ? props.component.location.pathname.split('/edit-category/')[1]
        : undefined;

    const [categoryForm, setCategoryForm] = useState({
        name: categoryRedux?.name,
        description: '',
        images: categoryRedux?.images,
        rootCategoryId: categoryRedux?.rootCategoryId,
    });

    useEffect(() => {
        AdminUsersModel.getAll()
            .then(res => setUsers([{ firstName: '', lastName: '' }, ...res]))
            .catch(err => console.log({ err }))
    }, [])


    useEffect(() => {
        console.log({ dispatchers })
    }, [dispatchers])
    const addCategory = () => {
        RootCategoryModel.updateCategory(categoryRedux.id, {
            ...categoryForm,
            type,
            ...(dispatchers && dispatchers.length > 0 && {
                meta: {
                    dispatchers: dispatchers.map(x =>
                    ({
                        ...x,
                        days: x.days && x.days.length > 0 ? x.days.reduce((a, b) => a + b) : x.days
                    })
                    )
                }
            })
        }).then(res => {
            console.log(res);
            history.push(`/add-rootCategory/${param}`);
        });
    };

    const onCancel = () => {
        history.push(`/add-rootCategory/${param}`);
    };

    const commTypes = [
        {},
        {
            name: 'Email',
            value: 0
        },
        {
            name: 'SMS',
            value: 1
        }
    ];
    const providerTypes = [
        {},
        {
            name: 'Restaurant',
            value: 0
        },
        {
            name: 'Valet',
            value: 1
        },
        {
            name: 'Housekeeping',
            value: 2
        },
        {
            name: 'Amenities',
            value: 3
        }
    ];

    const onDayClick = (dispatcher, day, checked, idx) => {
        const days = checked ? dispatcher.days + Number(day) : dispatcher.days - day;
        setDispatchers(dispatchers.map((x, id) => idx === id ? ({ ...x, days }) : x))
    };

    console.log({ categories })

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header>
                        <div className="flex-between">
                            <div>
                                <h1 className="main-title">
                                    {getTranslatedText('editCategory', store)}
                                </h1>
                            </div>
                            <div className="side-button grid-flex">
                                <button onClick={onCancel} style={{ marginRight: 12 }}>
                                    {getTranslatedText('cancel', store)}
                                </button><button onClick={addCategory} type="submit" className="btn default-btn">
                                    {getTranslatedText('save', store)}
                                </button>
                                <button className="rootCategory-toggle" aria-controls="primary-rootCategory" aria-expanded="false" title="rootCategory">
                                    <span className="rootCategory-icon">
                                        <span className="first"></span>
                                        <span className="second"></span>
                                        <span className="third"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </header>
                    <main className="spacing">
                        <div className="info info-title" data-info="category_name">
                            <label htmlFor="category_name" className="title-small">{getTranslatedText('categoryName', store)} <sup>(*)</sup></label>
                            <input
                                value={categoryForm.name}
                                onChange={event =>
                                    setCategoryForm({
                                        ...categoryForm,
                                        name: event.target.value,
                                    })
                                }
                                id="category_name" name="category_name" type="text" />
                        </div>
                        {!isDinein && dispatchers.length > 0 &&
                            dispatchers.map((dispatcher, idx) =>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 38
                                    }}
                                >
                                    <div>
                                        <label htmlFor="category_name">{getTranslatedText('agent', store)} <sup>(*)</sup></label>
                                        <br />
                                        <select value={dispatcher.userId} style={{ height: 30 }} onChange={event => {
                                            setDispatchers(dispatchers.map((x, id) => id === idx ? { ...dispatcher, userId: Number(event.target.value) } : x))
                                        }}>
                                            {users.map(x => <option value={x.id}>{`${x.firstName} ${x.lastName}`}</option>)}
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="category_name">{getTranslatedText('providerComm', store)} <sup>(*)</sup></label>
                                        <br />
                                        <select style={{ height: 30 }} defaultValue={dispatcher.comm} onChange={event => {
                                            setDispatchers(dispatchers.map((x, id) => id === idx ? { ...dispatcher, comm: Number(event.target.value) } : x))
                                        }}>
                                            {commTypes.map(x => <option value={x.value}>{x.name}</option>)}
                                        </select>
                                    </div>
                                    <div>
                                        <label>{getTranslatedText('openStart', store)} <sup>(*)</sup></label>
                                        <span className="custom-input-time margin-top">
                                            <input
                                                type="time"
                                                id="time-start"
                                                name="time-start"
                                                className="margin-top-half"
                                                placeholder=" "
                                                defaultValue={HCNTimeSettings.getTimeFromSum(dispatcher.from)}
                                                onChange={(event) => {
                                                    setDispatchers(dispatchers.map((x, id) => id === idx ? { ...dispatcher, from: HCNTimeSettings.getSumFromTime(event.target.value) } : x));
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <div style={{ marginLeft: 8 }}>
                                        <div className=" time-option">
                                            <label htmlFor="time-end">{getTranslatedText('closeEnd', store)} <sup>(*)</sup></label>
                                            <span className="custom-input-time margin-top">
                                                <input
                                                    type="time"
                                                    id="time-end"
                                                    name="time-end"
                                                    className="margin-top-half"
                                                    placeholder=" "
                                                    defaultValue={HCNTimeSettings.getTimeFromSum(dispatcher.to)}
                                                    onChange={(event) => {
                                                        setDispatchers(dispatchers.map((x, id) => id === idx ? { ...dispatcher, to: HCNTimeSettings.getSumFromTime(event.target.value) } : x));
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="time-option">
                                        <div>{getTranslatedText('days', store)} <sup>(*)</sup></div>
                                        <div className="grid-flex margin-top">
                                            <label className="days custom-input-days">
                                                <input
                                                    name="sunday"
                                                    type="checkbox"
                                                    value={1}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(1, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="monday"
                                                    type="checkbox"
                                                    value={2}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(2, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('M', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="thursday"
                                                    type="checkbox"
                                                    value={4}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(4, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="wednesday"
                                                    type="checkbox"
                                                    value={8}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(8, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('W', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="tuesday"
                                                    type="checkbox"
                                                    value={16}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(16, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="friday"
                                                    type="checkbox"
                                                    value={32}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(32, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('F', store)}</span>
                                            </label>
                                            <label className="days custom-input-days">
                                                <input
                                                    name="saturday"
                                                    type="checkbox"
                                                    value={64}
                                                    defaultChecked={HCNTimeSettings.isDayIncluded(64, dispatcher.days)}
                                                    onChange={({ target: { value, checked } }) => {
                                                        onDayClick(dispatcher, value, checked, idx)
                                                    }}
                                                />
                                                <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        className="days custom-input-days"
                                        onClick={() => {
                                            setDispatchers(dispatchers.filter((_, id) => id !== idx))
                                        }}
                                    >
                                        X
                                    </button>
                                </div>)}
                        {!isDinein && <button className="btn default-btn margin-top" onClick={() => {
                            setDispatchers([...dispatchers, {
                                days: [],
                                from: 0,
                                to: 0,
                                userId: null,
                                comm: 0
                            }])
                        }}>
                            Add New
                        </button>}
                        <br />
                        {!isDinein && <div style={{ height: 50 }} />}
                        {!isDinein && <label htmlFor="category_name">{getTranslatedText('providerComm', store)} <sup>(*)</sup></label>}
                        <br />
                        {!isDinein && <select style={{ height: 30 }} defaultValue={type} onChange={event => {
                            setType(Number(event.target.value))
                        }}>
                            {providerTypes.map(x => <option value={x.value}>{x.name}</option>)}
                        </select>}
                        {!isDinein && <div style={{ height: 50 }} />}
                        <form method="get" action="/search" className="searchbox margin-top ">
                            <h3 className="title-small">{getTranslatedText('iconList', store)}</h3>
                            <div className="search-bar-container">
                                <label htmlFor="searchBar" className="screen-reader-text">{getTranslatedText('searchbar', store)}</label>
                                <input type="search"
                                    id="searchBar"
                                    placeholder="Search an icon"
                                    onChange={event => {
                                        let text = event.target.value;
                                        if (text.length !== 0) {
                                            let newCategories = {};
                                            for (const iconCat of Object.keys(CategoryIcons.icons)) {
                                                let icons = [...CategoryIcons.icons[iconCat]].filter(x => x.toLowerCase().includes(text.toLowerCase()));
                                                newCategories[iconCat] = icons;
                                            }
                                            setIconCategories(newCategories);
                                        } else {
                                            setIconCategories(CategoryIcons.icons);
                                        }
                                    }}
                                />
                                <span className="search-btn flex-center">
                                    <img src={magnifier} alt="image" />
                                </span>
                            </div>
                        </form>

                        <div className="container__icons">
                            <div className="icon-category">
                                {Object.keys(iconCategories).map((iconCat, idx) =>
                                    <div className="icons-container" key={idx}>
                                        <h4 className="category-title">{iconCat.toUpperCase()}</h4>
                                        <div className="icons-wrapper grid-flex">
                                            {iconCategories[iconCat].map((category, index) =>
                                                <CategoryIcon
                                                    categoryForm={categoryForm}
                                                    setCategoryForm={setCategoryForm}
                                                    categories={categories}
                                                    setCategories={setCategories}
                                                    key={index + 'ic'}
                                                    component={{
                                                        icon: CategoryIcons.getIconUrlByName(category),
                                                        name: category,
                                                    }}
                                                />,
                                            )}
                                        </div>
                                    </div>,
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;
