import React, { Component } from 'react';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';

const Tabs = ({ setActiveTab, activeTab }) => {
    const handleTab1 = () => {
        setActiveTab('tab1');

    };
    const handleTab2 = () => {
        setActiveTab('tab2');
    };

    return (
        <div className="buttons-on-top">
            <button
                type="button"
                className={`default-btn toggle-btn  ${activeTab === 'tab1' ? 'is-active' : ''}`}
                onClick={() => handleTab1()}

                data-connection="content_rest_info">
                <span>
                    {getTranslatedText('providerInfo', store)}
                </span>
            </button>
            <button
                type="button"
                className={`default-btn toggle-btn  ${activeTab === 'tab2' ? 'is-active' : ''}`}
                onClick={handleTab2}
                data-connection="content_fees">
                <span>{getTranslatedText('feesTaxes', store)}</span>
            </button>
        </div>
    );
};
export default Tabs;
