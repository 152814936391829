import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ItemModel from '../../data/item-model';
import { default as magnifier } from '../magnifying-glass.svg';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import RootCategoryItem from './rootCategory-item';
import HCNLoader from '../../helpers/hcn-loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const RootCategoryItems = () => {
    const [items, setItems] = useState([]);
    const [searcheditems, setSearchedItems] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        // HCNLoader.show(dispatch);
        ItemModel
            .getRootCategoryItems(FilterModel.providerId)
            .then(res => {
                if (res.length === 0) {
                    history.push('/add-rootCategory-item');
                    return;
                }
                setItems(res);
                setSearchedItems(res);
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
        return () => setItems([]);
    }, []);

    const searchItems = ({ target: { value } }) => {
        value
            ? setSearchedItems(
                items.filter(x =>
                    x.name.toLowerCase().includes(value.toLowerCase()) ||
                    x.description.toLowerCase().includes(value.toLowerCase()),
                ),
            )
            : setSearchedItems(items)
    };

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">
                                {getTranslatedText('items', store)}
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    <button type="submit" className="btn default-btn">
                                        {getTranslatedText('save', store)}
                                    </button>
                                    <MainMenuBtn />
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="spacing">
                        <div className="search-bar-container">
                            <label htmlFor="searchBar" className="screen-reader-text">
                                {getTranslatedText('searchbar', store)}
                            </label>
                            <input type="search" id="searchBar" placeholder="search an item" onChange={searchItems} />
                            <button className="search-btn flex-center">
                                <img src={magnifier} alt="image" />
                            </button>
                        </div>

                        <div className="">
                            <div className="grid has-col-15 border-bottom padding-top-double padding-bottom">
                                <div className="grid-item custom-grid-1-1 grid-start">
                                </div>
                                <div className="grid-item custom-grid-2-4">
                                    <h3 className="clearfix">{getTranslatedText('rootCategoryItems', store)}</h3>
                                </div>
                                <div className="grid-item custom-grid-3-2">
                                    <p className="mods-filter-opt text-center">{getTranslatedText('filter', store)}</p>
                                </div>
                                <div className="grid-item custom-grid-4-2">
                                    <p className="text-center">{getTranslatedText('price', store)}</p>
                                </div>
                                <div className="grid-item custom-grid-5-2">
                                    <p className="text-center">{getTranslatedText('availability', store)}</p>
                                </div>
                                <div className="grid-item custom-grid-6-1">

                                </div>
                            </div>

                            <div className="grid has-col-15 padding-top padding-bottom">
                                {searcheditems && searcheditems.sort((a, b) => a.updated < b.updated ? 1 : -1).map(item => <RootCategoryItem
                                    key={item.id}
                                    items={items}
                                    setItems={setItems}
                                    item={item} />)}
                            </div>

                        </div>
                    </main>
                </div>

            </div>
        </div>
    );
};
export default RootCategoryItems;
