import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FilterModel from '../../data/filter-model';
import ModifierModel from '../../data/modifier-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import AddModifierProviderFields from './add-modifier-provider-fields';
import RawItem from './raw-item';
import { toast } from 'react-toastify';
import { store } from '../../redux/store';
import { getTranslatedText } from '../../helpers/checkTranslations';

const AddModifier = props => {
    const history = useHistory();

    const param = props.component
        ? props.component.location.pathname.split('/add-modifier/')[1]
        : undefined;
    const [modifier, setModifier] = useState({
        name: '',
        required: false,
        type: 0,
        title: '',
        min: 0,
        max: 0,
        rawItems: [],
    });
    const [filters, setFilters] = useState([]);
    const [draggedList, updateDraggedList] = useState(null);

    const setModifierFouUpdateOrDouble = (obj) => {
        setModifier({
            name: obj.name ? obj.name : '',
            required: obj.required ? obj.required : false,
            type: obj.type ? obj.type : 0,
            title: obj.title ? obj.title : '',
            min: obj.min ? obj.min : 0,
            max: obj.max ? obj.max : 0,
            rawItems: obj.rawItems.map(it => {
                return {
                    ...it,
                    name: it.name,
                    price: it.price,
                    filtersOut: it.filtersOut.map(fo => {
                        return {
                            ...fo,
                            selected: true,
                        };
                    }),
                };

            }),
        });

    };
    const getHCNFilters = () => {
        FilterModel.remote_getAll().then(filters => {
            let flts = filters.map(filter => {
                return {
                    ...filter,
                    selected: false,
                };
            });
            setFilters(flts);
        });
    };

    const getProviderFilters = () => {
        FilterModel.remote_getAllById(FilterModel.providerId).then(filters => {
            // if (filters.length > 0){
            let flts = filters.map(filter => {
                return {
                    ...filter,
                    selected: false,
                };
            });
            setFilters(flts);
            // } else {
            //     history.push('/add-filter');
            // }
        });
    };

    useEffect(() => {
        FilterModel.providerId ? getProviderFilters() : getHCNFilters();
        if (param) {
            let params = param.split('/');
            FilterModel.providerId ? ModifierModel.getProviderModifierById(params[0]).then((res) => {

                setModifierFouUpdateOrDouble(res);
            }) :
                ModifierModel.getModifierById(params[0]).then((res) => {
                    setModifierFouUpdateOrDouble(res);
                });
        }
        return () => {
            setFilters([]);
            setModifier({});
        };
    }, []);

    useEffect(() => {
        updateDraggedList(modifier.rawItems);
    }, [modifier]);

    const createOrUpdateProviderModifier = (modif, param) => {
        let params = param ? param.split('/') : undefined;

        if (params) {
            if (params[2] && params[1] === modif.name) {
                toast(getTranslatedText('cloneModifierWarning', store));
            } else if (params[2] && params[1] !== modif.name) {
                ModifierModel.createProviderModifiers({ modifiers: [modif], providerId: FilterModel.providerId })
                    .then(() => history.push('/modifiers'))
                    .catch(err => toast(err.message, { autoclose: 1200 }));
            } else if (!params[2]) {
                ModifierModel.updateProviderModifiers(modif, params[0])
                    .then(() => history.push('/modifiers'))
                    .catch(err => toast(err.message, { autoclose: 1200 }));
            }
        } else {
            ModifierModel.createProviderModifiers({ modifiers: [modif], providerId: FilterModel.providerId })
                .then(() => history.push('/modifiers'))
                .catch(err => toast(err.message, { autoclose: 1200 }));
        }
    };

    const createOrUpdateModifier = (modif, param) => {
        let params = param ? param.split('/') : undefined;

        if (params) {
            if (params[2] && params[1] === modif.name) {
                toast(getTranslatedText('cloneModifierWarning', store));
            } else if (params[2] && params[1] !== modif.name) {
                ModifierModel.createModifier(modif)
                    .then(() => history.push('/modifiers'))
                    .catch(err => toast(err.message, { autoclose: 1200 }));
            } else if (!params[2]) {
                ModifierModel.updateModifier(params[0], modif)
                    .then(() => history.push('/modifiers'))
                    .catch(err => toast(err.message, { autoclose: 1200 }));
            }
        } else {
            ModifierModel.createModifier(modif)
                .then(() => history.push('/modifiers'))
                .catch(err => toast(err.message, { autoclose: 1200 }));
        }
    };

    const addModifierForm = () => {
        let modif = {
            ...modifier,
            rawItems: modifier.rawItems.map(item => {
                return {
                    ...item,
                    name: item.name ? item.name : '',
                    filtersOut: item.filtersOut ? item.filtersOut.map(item => item.id) : [],
                };
            }),
        };
        FilterModel.providerId ? createOrUpdateProviderModifier(modif, param) : createOrUpdateModifier(modif, param);
    };

    const cancelAction = () => {
        history.push('/modifiers');
    };

    const addRawItem = () => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.concat({}).map((item, index) => {
                return { ...item, id: index };
            }),
        });
    };

    const handleOnDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(draggedList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateDraggedList(items);
        setModifier({ ...modifier, rawItems: items });

    };

    return (
        <div>
            <div className="modal-layer"></div>

            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className='site-header'>
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText('addModifier', store)}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button onClick={cancelAction} type='button' className="btn">
                                            {getTranslatedText('cancel', store)}
                                        </button>
                                        <button onClick={() => {
                                            if (modifier.type === 1 && modifier.min >= modifier.max) {
                                                toast(getTranslatedText('minMaxError', store));
                                            } else if (modifier.type === 1 && (modifier.min > modifier.rawItems.length || modifier.max > modifier.rawItems.length || modifier.min < 0 || modifier.max < 0)) {
                                                toast(getTranslatedText('minMaxValidWarning', store));
                                            } else {
                                                addModifierForm();
                                            }
                                        }
                                        } className="btn default-btn">
                                            {getTranslatedText('save', store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="spacing">
                            <div className="info info-title" data-info="filter_name">
                                <label htmlFor="filter_name">
                                    {getTranslatedText('modifierName', store)}
                                    <sup>(*)</sup>
                                </label>
                                <input value={modifier.name}
                                    onChange={event =>
                                        setModifier({
                                            ...modifier,
                                            name: event.target
                                                .value,
                                        })}
                                    id="filter_name" name="filter_name" type="text" />
                            </div>
                            <div className="info info-title" data-info="filter_name">
                                <label htmlFor="filter_name">
                                    {getTranslatedText('displayForGuests', store)}
                                    <sup>(*)</sup>
                                </label>
                                <input value={modifier.title} id="filter_name" onChange={event =>
                                    setModifier({
                                        ...modifier,
                                        title: event.target
                                            .value,
                                    })} name="filter_name" type="text" />
                            </div>
                            {FilterModel.providerId ? <AddModifierProviderFields modifier={modifier} setModifier={setModifier} /> : <></>}

                            <section className="main-section">
                                <div className="section-head flex-end">
                                    <button onClick={addRawItem} type="button" className="default-btn">
                                        {getTranslatedText('addAnOption', store)}
                                    </button>
                                </div>
                                <div className="section-body options-wrapper">
                                    <div className="header flex-between border-bottom">
                                        <div className="empty-space-50"></div>
                                        <div className="flex-between-1">
                                            <h3>
                                                {getTranslatedText('modifierOptions', store)}
                                            </h3>
                                            <div className="flex-end">
                                                <p className="mods-filter-opt">
                                                    {getTranslatedText('filter', store)}
                                                </p>
                                                {FilterModel.providerId ? <>
                                                    <p className="width-150">
                                                        {getTranslatedText('price', store)}
                                                    </p>
                                                </> : <></>}
                                                <div className="empty-space-150"></div>

                                            </div>
                                        </div>
                                    </div>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId='modifiers'>
                                            {provided =>
                                                <div {...provided.droppableProps} ref={provided.innerRef} >
                                                    {modifier.rawItems && modifier.rawItems.length > 0 && draggedList?.map((item, index) =>
                                                        <Draggable key={index} index={index} draggableId={`${item.name}${item.id}`}>
                                                            {provided =>
                                                                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                    <RawItem
                                                                        key={index}
                                                                        index={index}
                                                                        setFilters={setFilters}
                                                                        filters={filters}
                                                                        modifier={modifier}
                                                                        setModifier={setModifier}
                                                                        item={item}
                                                                    />
                                                                </div>
                                                            }
                                                        </Draggable>,
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddModifier;
