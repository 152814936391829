import React, { Component, useRef } from 'react';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ItemModel from '../../data/item-model';
import FilterModel from '../../data/filter-model';
import TimeFrameModel from '../../data/timeframe-model';
import { useDispatch } from 'react-redux';
import Config from '../../Config';
import HCNTimeSettings from '../../helpers/hcn-time-settings';
import RootCategoryModel from '../../data/rootCategory-model';
import HCNLoader from '../../helpers/hcn-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './time-settings.css'
import Multiselect from 'multiselect-react-dropdown';
import { store } from '../../redux/store';
import { getTranslatedText } from '../../helpers/checkTranslations';

const TimeSettings = () => {
    const [timeFrames, setTimeFrames] = useState([]);
    const [tfr, setTf] = useState([]);
    const [rootCategories, setRootCategories] = useState([]);
    const [initRootCategorys, setInitRootCategorys] = useState([]);
    const [bool, setBool] = useState(true);
    const [mode, setMode] = useState(Config.TIME_SETTINGS_MODE.DEFAULT);
    const history = useHistory();
    const dispatch = useDispatch();
    const groupBy = (array, f) => {
        let groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }
    useEffect(() => {
        HCNLoader.show(dispatch);
        TimeFrameModel
            .getAllTimeFramesByProviderId(FilterModel.providerId)
            .then(res => {
                res.sort((a, b) => {
                    return a.startTime < b.startTime ? -1 : 1;
                });
                const arr = groupBy(res, x => [x.days, x.startTime, x.endTime]).filter(x => !x.date).map((x, i) => !x.date && [{
                    ...x[0], rootCategoryId: x.map(o => ({ name: o.rootCategoryName, id: o.rootCategoryId })),
                }])
                const newArr = arr.map(x => ({ ...x[0] }))

                const df = newArr.filter(x => x.days)
                const se = newArr.filter(x => x.date)
                setTimeFrames(df.concat(se))

                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
        RootCategoryModel
            .getAllRootCategorys(FilterModel.providerId)
            .then(async res => {
                let rootCategories = res.map(item => {
                    return { ...item, selected: false };
                });
                setRootCategories(rootCategories);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
        return () => setTimeFrames([]);
    }, []);
    const onDayClick = (timeFrame, day, checked) => {
        timeFrame.days = checked ? timeFrame.days + day : timeFrame.days - day;
        setTimeFrames([...timeFrames]);
    };
    const addNewTimeSettingRow = () => {
        let tf = [...timeFrames];
        if (mode === Config.TIME_SETTINGS_MODE.DEFAULT) {
            const df = tf.filter(x => x.days)
            const se = tf.filter(x => x.date)
            df.push({ days: 0, date: null })
            tf = df.concat(se)
        } else if (mode === Config.TIME_SETTINGS_MODE.SPECIAL) {
            const df = tf.filter(x => x.days)
            const se = tf.filter(x => x.date)
            se.push({ days: null, date: null, endDate: null })
            tf = df.concat(se)
        }
        setTimeFrames(tf);
    };
    const onSelectRootCategorys = (list, index) => {
        setTimeFrames(timeFrames.map((tf, idx) => {
            console.log({ tf, idx, index, list })
            return idx === index ? {
                ...tf,
                rootCategoryId: list,
            } : tf
        }))
        setInitRootCategorys([...initRootCategorys, list[list.length - 1]])
    };
    const onRemoveRootCategorys = (list, index) => {
        setTimeFrames(timeFrames.map((tf, idx) => idx === index ? {
            ...tf,
            rootCategoryId: list,
        } : tf))
    };
    const onSaveClick = () => {
        let nonValidatedItems = timeFrames.filter(item => !item.rootCategoryId);
        console.log(nonValidatedItems)
        if (nonValidatedItems.length === 0) {
            HCNLoader.show(dispatch);
            const tf1 = timeFrames.map(item =>
                typeof item.rootCategoryId !== 'string' ? item.rootCategoryId.map(rootCategory => ({
                    days: item.days,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    rootCategoryId: rootCategory.id,
                    date: item.date ? item.date : null,
                    endDate: item.endDate ? item.endDate : item.date,
                })) : [{
                    days: item.days,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    rootCategoryId: item.rootCategoryId,
                    date: item.date ? item.date : null,
                    endDate: item.endDate ? item.endDate : item.date,
                }],
            )
            let finalArray = []
            tf1.map(x => x.map(o => finalArray.push(o)))
            console.log({ finalArray })
            TimeFrameModel
                .update(FilterModel.providerId, finalArray)
                .then(() => {
                    HCNLoader.hide(dispatch);
                    if (bool) {
                        toast(getTranslatedText('tfSaved', store))
                        setBool(false)
                    }
                })
                .catch(err => {
                    if (bool) {
                        toast(err.message);
                        setBool(false)
                    }
                    HCNLoader.hide(dispatch);
                })
            setBool(true)
        } else {
            toast.error(getTranslatedText('addAllFields', store));
            setBool(true)
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className="divider grid-flex">
                <SideBar timeSettingsAddClicked={() => {
                    addNewTimeSettingRow();
                }} />
                <div id="page" className="site">
                    <div className="main-content time-frame">
                        <form >
                            <header className='site-header'>
                                <div className="flex-between">
                                    <h1 className="main-title">
                                        {getTranslatedText('timeSettings', store)}
                                    </h1>
                                    <div className="side-functionality">
                                        <div className="side-button grid-flex">
                                            {
                                                timeFrames.length > 0 &&
                                                <button type="button" className="btn default-btn" onClick={addNewTimeSettingRow}>
                                                    {getTranslatedText('addTimeFrame', store)}
                                                </button>
                                            }
                                            <button type="button" className="btn default-btn" onClick={onSaveClick}>
                                                {getTranslatedText('save', store)}
                                            </button>
                                            <MainMenuBtn />
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <main>
                                <section className="tabbed" style={{ marginBottom: 200 }}>
                                    {timeFrames.length > 0 &&
                                        <div className="flex-between">
                                            <div className="tabbs grid-flex">
                                                <button
                                                    type="button"
                                                    className={'tab tab-1' + (mode === Config.TIME_SETTINGS_MODE.DEFAULT ? ' active' : '')}
                                                    onClick={() => setMode(Config.TIME_SETTINGS_MODE.DEFAULT)}
                                                    data-tab="default-provider-settings">
                                                    {getTranslatedText('defaultProviderSettings', store)}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={'tab tab-2' + (mode === Config.TIME_SETTINGS_MODE.SPECIAL ? ' active' : '')}
                                                    onClick={() => setMode(Config.TIME_SETTINGS_MODE.SPECIAL)}
                                                    data-tab="special-events">
                                                    {getTranslatedText('specialEvents', store)}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {timeFrames.length < 1 &&
                                        <div>
                                            <button
                                                type="button"
                                                className="btn default-btn"
                                                onClick={() => {
                                                    addNewTimeSettingRow();
                                                }}>
                                                {getTranslatedText('addTimeFrame', store)}
                                            </button>
                                        </div>
                                    }
                                    <div className="container time-settings">
                                        <div className="tab-windows active" data-tab="default-provider-settings">
                                            {timeFrames.filter(x => mode === Config.TIME_SETTINGS_MODE.DEFAULT && x.date === null).length > 0 &&
                                                timeFrames.filter(x => mode === Config.TIME_SETTINGS_MODE.DEFAULT ? x.days !== null : x.date !== null)
                                                    .map((timeFrame, index) =>
                                                        <div className="tab-window border-bottom padding-bottom" key={index}>
                                                            <div className="margin-top-double">
                                                                <div className="flex-wrap flex-start padding-right">
                                                                    <div className="margin-top-4 time-option">
                                                                        <label htmlFor="time-start">{getTranslatedText('openStart', store)} <sup>(*)</sup></label>
                                                                        <span className="custom-input-time margin-top">
                                                                            <input
                                                                                type="time"
                                                                                id="time-start"
                                                                                name="time-start"
                                                                                className="margin-top-half"
                                                                                placeholder=" "
                                                                                value={HCNTimeSettings.getTimeFromSum(timeFrame.startTime)}
                                                                                onChange={(event) => {
                                                                                    timeFrame.startTime = HCNTimeSettings.getSumFromTime(event.target.value);
                                                                                    setTimeFrames([...timeFrames]);
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div className="margin-top-4 time-option">
                                                                        <label htmlFor="time-end">{getTranslatedText('closeEnd', store)} <sup>(*)</sup></label>
                                                                        <span className="custom-input-time margin-top">
                                                                            <input
                                                                                type="time"
                                                                                id="time-end"
                                                                                name="time-end"
                                                                                className="margin-top-half"
                                                                                placeholder=" "
                                                                                value={HCNTimeSettings.getTimeFromSum(timeFrame.endTime)}
                                                                                onChange={(event) => {
                                                                                    timeFrame.endTime = HCNTimeSettings.getSumFromTime(event.target.value);
                                                                                    setTimeFrames([...timeFrames]);
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {mode === Config.TIME_SETTINGS_MODE.DEFAULT &&
                                                                        <div className="margin-top-4 time-option">
                                                                            <div>{getTranslatedText('days', store)} <sup>(*)</sup></div>
                                                                            <div className="grid-flex margin-top">
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="sunday"
                                                                                        type="checkbox"
                                                                                        value="1"
                                                                                        checked={HCNTimeSettings.isDayIncluded(1, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 1, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="monday"
                                                                                        type="checkbox"
                                                                                        value="2"
                                                                                        checked={HCNTimeSettings.isDayIncluded(2, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 2, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('M', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="thursday"
                                                                                        type="checkbox"
                                                                                        value="4"
                                                                                        checked={HCNTimeSettings.isDayIncluded(4, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 4, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="wednesday"
                                                                                        type="checkbox"
                                                                                        value="8"
                                                                                        checked={HCNTimeSettings.isDayIncluded(8, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 8, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('W', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="tuesday"
                                                                                        type="checkbox"
                                                                                        value="16"
                                                                                        checked={HCNTimeSettings.isDayIncluded(16, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 16, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="friday"
                                                                                        type="checkbox"
                                                                                        value="32"
                                                                                        checked={HCNTimeSettings.isDayIncluded(32, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 32, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('F', store)}</span>
                                                                                </label>
                                                                                <label className="days custom-input-days">
                                                                                    <input
                                                                                        name="saturday"
                                                                                        type="checkbox"
                                                                                        value="64"
                                                                                        checked={HCNTimeSettings.isDayIncluded(64, timeFrame.days)}
                                                                                        onChange={(event) => {
                                                                                            onDayClick(timeFrame, 64, event.target.checked);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="margin-top-4 time-option">
                                                                        <label>
                                                                            {getTranslatedText('rootCategory', store)}
                                                                            <sup>(*)</sup>
                                                                        </label>
                                                                        <Multiselect
                                                                            options={rootCategories}
                                                                            onSelect={(list) => onSelectRootCategorys(list, index)}
                                                                            onRemove={(list) => onRemoveRootCategorys(list, index)}
                                                                            displayValue="name"
                                                                            selectedValues={timeFrames && timeFrames[index]
                                                                                && timeFrames[index].rootCategoryId
                                                                                && timeFrames[index].rootCategoryId.length > 0
                                                                                ? timeFrames[index].rootCategoryId.filter(m => m !== null && m.name !== null && m.id !== null).map(m => ({
                                                                                    name: m.name,
                                                                                    id: m.id,
                                                                                }),
                                                                                ) : []}
                                                                        />
                                                                    </div>
                                                                    <div className="margin-top-double flex-between">
                                                                        <button
                                                                            type="button"
                                                                            className="btn-remove"
                                                                            onClick={() => {
                                                                                let tf = [...timeFrames].filter(x => !(x.id === timeFrame.id && (x.days === timeFrame.days || x.date === timeFrame.date)));
                                                                                setTimeFrames(tf);
                                                                            }}>
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>,
                                                    )}
                                            {timeFrames.filter(x => mode === Config.TIME_SETTINGS_MODE.SPECIAL && x.days === null)
                                                .map((timeFrame, index) =>
                                                    <div className="tab-window border-bottom padding-bottom" key={index}>
                                                        <div className="margin-top-double">
                                                            <div className="flex-wrap flex-start padding-right">
                                                                <div className="margin-top-4 time-option">
                                                                    <label htmlFor="time-start">{getTranslatedText('openStart', store)} <sup>(*)</sup></label>
                                                                    <span className="custom-input-time margin-top">
                                                                        <input
                                                                            type="time"
                                                                            id="time-start"
                                                                            name="time-start"
                                                                            className="margin-top-half"
                                                                            placeholder=" "
                                                                            value={HCNTimeSettings.getTimeFromSum(timeFrame.startTime)}
                                                                            onChange={(event) => {
                                                                                timeFrame.startTime = HCNTimeSettings.getSumFromTime(event.target.value);
                                                                                setTimeFrames([...timeFrames]);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="margin-top-4 time-option">
                                                                    <label htmlFor="time-end">{getTranslatedText('closeEnd', store)} <sup>(*)</sup></label>
                                                                    <span className="custom-input-time margin-top">
                                                                        <input
                                                                            type="time"
                                                                            id="time-end"
                                                                            name="time-end"
                                                                            className="margin-top-half"
                                                                            placeholder=" "
                                                                            value={HCNTimeSettings.getTimeFromSum(timeFrame.endTime)}
                                                                            onChange={(event) => {
                                                                                timeFrame.endTime = HCNTimeSettings.getSumFromTime(event.target.value);
                                                                                setTimeFrames([...timeFrames]);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                {mode === Config.TIME_SETTINGS_MODE.DEFAULT &&
                                                                    <div className="margin-top-4 time-option">
                                                                        <div>{getTranslatedText('days', store)} <sup>(*)</sup></div>
                                                                        <div className="grid-flex margin-top">
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="sunday"
                                                                                    type="checkbox"
                                                                                    value="1"
                                                                                    checked={HCNTimeSettings.isDayIncluded(1, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 1, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="monday"
                                                                                    type="checkbox"
                                                                                    value="2"
                                                                                    checked={HCNTimeSettings.isDayIncluded(2, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 2, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('M', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="thursday"
                                                                                    type="checkbox"
                                                                                    value="4"
                                                                                    checked={HCNTimeSettings.isDayIncluded(4, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 4, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="wednesday"
                                                                                    type="checkbox"
                                                                                    value="8"
                                                                                    checked={HCNTimeSettings.isDayIncluded(8, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 8, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('W', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="tuesday"
                                                                                    type="checkbox"
                                                                                    value="16"
                                                                                    checked={HCNTimeSettings.isDayIncluded(16, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 16, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('T', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="friday"
                                                                                    type="checkbox"
                                                                                    value="32"
                                                                                    checked={HCNTimeSettings.isDayIncluded(32, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 32, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('F', store)}</span>
                                                                            </label>
                                                                            <label className="days custom-input-days">
                                                                                <input
                                                                                    name="saturday"
                                                                                    type="checkbox"
                                                                                    value="64"
                                                                                    checked={HCNTimeSettings.isDayIncluded(64, timeFrame.days)}
                                                                                    onChange={(event) => {
                                                                                        onDayClick(timeFrame, 64, event.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark flex-center">{getTranslatedText('S', store)}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {mode === Config.TIME_SETTINGS_MODE.SPECIAL &&
                                                                    <div className="margin-top-4 time-option">
                                                                        <label htmlFor="event-date"
                                                                            className="font-uppercase">{getTranslatedText('startDate', store)} <sup>(*)</sup></label>
                                                                        <span className="custom-input-date margin-top">
                                                                            <input
                                                                                type="date"
                                                                                id="event-date"
                                                                                className="margin-top-half"
                                                                                name="event-date"
                                                                                value={timeFrame.date}
                                                                                onChange={(e) => {
                                                                                    timeFrame.date = e.target.value;
                                                                                    setTimeFrames([...timeFrames]);
                                                                                }}
                                                                            />
                                                                            <span className="date-icon">
                                                                                <svg width="30" height="30" viewBox="0 0 30 30"
                                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                                        d="M25.5 4.5H24V1.5C24 0.671573 23.3284 0 22.5 0C21.6716 0 21 0.671573 21 1.5V4.5H9V1.5C9 0.671573 8.32843 0 7.5 0C6.67157 0 6 0.671573 6 1.5V4.5H4.5C2.01472 4.5 0 6.51472 0 9V25.5C0 27.9853 2.01472 30 4.5 30H25.5C27.9853 30 30 27.9853 30 25.5V9C30 6.51472 27.9853 4.5 25.5 4.5ZM3 9C3 8.17157 3.67157 7.5 4.5 7.5H25.5C26.3284 7.5 27 8.17157 27 9V10.5H3V9ZM25.5 27C26.3284 27 27 26.3284 27 25.5V13.5H3V25.5C3 26.3284 3.67157 27 4.5 27H25.5Z"
                                                                                        fill="white" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="arrow-icon arrow down"></span>
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {mode === Config.TIME_SETTINGS_MODE.SPECIAL &&
                                                                    <div className="margin-top-4 time-option">
                                                                        <label htmlFor="event-date"
                                                                            className="font-uppercase">{getTranslatedText('endDate', store)}</label>
                                                                        <span className="custom-input-date margin-top">
                                                                            <input
                                                                                type="date"
                                                                                id="event-date"
                                                                                className="margin-top-half"
                                                                                name="event-date"
                                                                                value={timeFrame.endDate}
                                                                                onChange={(e) => {
                                                                                    timeFrame.endDate = e.target.value;
                                                                                    setTimeFrames([...timeFrames]);
                                                                                    console.log({ timeFrames })
                                                                                }}
                                                                            />
                                                                            <span className="date-icon">
                                                                                <svg width="30" height="30" viewBox="0 0 30 30"
                                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                                        d="M25.5 4.5H24V1.5C24 0.671573 23.3284 0 22.5 0C21.6716 0 21 0.671573 21 1.5V4.5H9V1.5C9 0.671573 8.32843 0 7.5 0C6.67157 0 6 0.671573 6 1.5V4.5H4.5C2.01472 4.5 0 6.51472 0 9V25.5C0 27.9853 2.01472 30 4.5 30H25.5C27.9853 30 30 27.9853 30 25.5V9C30 6.51472 27.9853 4.5 25.5 4.5ZM3 9C3 8.17157 3.67157 7.5 4.5 7.5H25.5C26.3284 7.5 27 8.17157 27 9V10.5H3V9ZM25.5 27C26.3284 27 27 26.3284 27 25.5V13.5H3V25.5C3 26.3284 3.67157 27 4.5 27H25.5Z"
                                                                                        fill="white" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="arrow-icon arrow down"></span>
                                                                        </span>
                                                                    </div>
                                                                }
                                                                <div className="margin-top-4 time-option">
                                                                    <label>{getTranslatedText('rootCategory', store)} <sup>(*)</sup></label>
                                                                    <select
                                                                        name="active-rootCategory"
                                                                        className="margin-top time-settings-event-rootCategory"
                                                                        value={timeFrame?.rootCategoryId?.[0]?.id}
                                                                        onChange={(event) => {
                                                                            timeFrame.rootCategoryId = event.target.value;
                                                                            setTimeFrames([...timeFrames]);
                                                                        }}>
                                                                        <option value={''} key={'default'}></option>
                                                                        {rootCategories.length > 0 && rootCategories.map((rootCategory, ind) =>
                                                                            <option className='black' value={rootCategory.id} key={ind}>{rootCategory.name}</option>,
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <div className="margin-top-double flex-between">
                                                                    <button
                                                                        type="button"
                                                                        className="btn-remove"
                                                                        onClick={() => {
                                                                            let tf = [...timeFrames].filter(x => !(x.id === timeFrame.id && (x.days === timeFrame.days || x.date === timeFrame.date)));
                                                                            setTimeFrames(tf);
                                                                        }}>
                                                                        X
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>,
                                                )}
                                        </div>
                                    </div>
                                </section>
                            </main>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TimeSettings;